<template>
  <div class="create-lobby">
    <a class="create-lobby-back-button"
         @click="$router.go(-1)"
>
      <ArrowLeft :size="32"></ArrowLeft>
    </a>

    <div
      class="create-lobby-form slideFromLeftAnimation shadow-1"
      @keyup.enter="createLobby"
    >
      <div class="form-element slideFromLeftAnimation">
        <span>{{ $t("create-lobby.lobby-name") }}</span>
        <input
          type="text"
          maxlength="16"
          @change="lobbyError = ''"
          v-model="lobby.name"
        />
      </div>

      <div class="form-element slideFromLeftAnimation-delay-01">
        <span>{{ $t("create-lobby.password") }}</span>
        <input type="password" v-model="lobby.password" />
      </div>

      <div class="form-element slideFromLeftAnimation-delay-02">
        <span>{{ $t("create-lobby.user-limit") }}</span>
        <input
          min="1"
          max="24"
          @change="lobbyError = ''"
          type="number"
          v-model="lobby.userLimit"
        />
      </div>

      <div class="form-element-error slideFromLeftAnimation">
        <span>{{ lobbyError }}</span>
      </div>
      <div class="form-element-button slideFromLeftAnimation">
        <button class="btn" @click="createLobby()">
          {{ $t("create-lobby.create-lobby") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCollection, addLobby } from "../firebase/db";
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";

export default {
  data() {
    return {
      lobbyError: "",
      lobby: {
        _id: null,
        name: this.$store.state.user.userName + " lobby",
        password: "",
        users: [],
        userLimit: 8,
        chat: [],
        startTime: null,
        isStarted: false,
        settings: {
          selectedCategories: ["all"],
          turnTime: 15,
          questionSize:20,
        },
        game: {},

        kickedUsers: [],
      },

      lobbies: [],
    };
  },

  title() {
    return "Create lobby | okuiz";
  },

  mounted() {
    this.getLobbies();
  },

  methods: {
    async getLobbies() {
      this.lobbies = await getCollection("lobbies");
    },

    createId() {
      const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let idLength = 6;
      let myId = "";
      let whichArray = ["number", "upper", "lower"];

      for (let i = 0; i < idLength; i++) {
        let selected = whichArray[Math.floor(Math.random() * 3)];
        if (selected == "upper") {
          myId = myId + alphabet[Math.floor(Math.random() * alphabet.length)];
        } else if (selected == "lower") {
          myId =
            myId +
            alphabet[Math.floor(Math.random() * alphabet.length)].toLowerCase();
        } else if (selected == "number") {
          myId = myId + Math.floor(Math.random() * 10);
        }
      }

      return myId;
    },

    async createLobby() {
      this.getLobbies();
      let id = "";

      let idNotUnique = true;
      while (idNotUnique) {
        id = this.createId();
        idNotUnique = this.lobbies.find((l) => {
          return l._id == id;
        });
      }

      let isPassworded = false;

      if (this.lobby.password.length > 0) {
        isPassworded = true;
      }

      this.lobby._id = id;
      this.lobby.createdAt = Date.now();
      this.lobby.isPassworded = isPassworded;
      if (this.lobby.name < 1 || this.lobby.userLimit > 24) {
        this.lobbyError = `${this.$t("create-lobby.errors.user-limit")}`;
      } else if (this.lobby.name < 1 || this.lobby.name > 16) {
        this.lobbyError = `${this.$t("create-lobby.errors.lobby-name")}`;
      } else {
        addLobby({ ...this.lobby });
        this.$router.push(`/game/${id}`);
      }
    },
  },

  components: {
    ArrowLeft,
  },
};
</script>

<style scoped lang="scss"></style>
