<template>
  <div class="kuizbook-allpage">
    <Navbar></Navbar>
    <div class="kuizbook-wrapper">
      <div class="flex align-center justify-center">
        <div class="line"></div>

        <h1 class="kuizbook-title">Kuizbook</h1>
        <div class="line"></div>
      </div>

      <h5
        class="kuizbook-subtitle flex justify-center"
        style="font-weight: normal; font-style: italic"
      >
        {{ $t("kuizbook.subtitle") }}
      </h5>
      <KuizbookSearch></KuizbookSearch>
    </div>
    <div class="kuizs-wrapper">
      <TransitionGroup
        name="card-list"
        tag="div"
        class="words-wrapper"
        v-if="!searchLoading"
      >
        <div
          class="question-hint-image-wrapper"
          v-for="(kuiz, index) in questions"
          :key="kuiz.rid"
          @mouseover="changeActiveHint(index)"
          @mouseleave="mouseleaveImage(index)"
          @click="goKuizInfo(kuiz)"
        >
          <div class="question-hint-image-border"></div>
          <img
            class="question-hint-image"
            :src="kuiz.hints[kuiz.activeHintNumber].url"
          />
          <div class="question-hint-image-container"></div>
          <div class="question-hint-title">
            {{ trimString(`${kuiz.answer}`, 22) }}
          </div>
          <div class="question-hint-hint-name">
            {{ trimString(`${kuiz.hints[kuiz.activeHintNumber].name}`, 22) }}
          </div>
          <CardCategoryIcon
            :category="kuiz.category"
            class="question-hint-image-category"
          >
          </CardCategoryIcon>
        </div>
      </TransitionGroup>
      <TransitionGroup name="card-list" tag="div" class="words-wrapper" v-else>
        <div
          class="loading-hint-image-wrapper"
          v-for="(kuiz, index) in kuizs"
          :key="index"
        >
          <div class="loading-hint-image-container"></div>
        </div>
      </TransitionGroup>
    </div>
    <div
      v-if="!searchLoading && questions && questions.length < 1"
      class="kuizbook-subtitle flex justify-center"
    >
      {{ $t("kuizbook.nothing-found") }}
    </div>
    <div class="pagination" v-if="pagesArray">
      <button
        class="pagination-nextprev-button"
        v-if="page != 1"
        @click="page = page - 1"
      >
        {{ $t("directions.previous") }}
      </button>
      <button
        class="pagination-button"
        v-if="!pagesArray.find((p) => p == 1)"
        @click="page = 1"
      >
        1
      </button>
      <div v-for="(pg, index) in pagesArray" :key="index">
        <button
          :class="page == pg ? 'pagination-button-selected' : ''"
          class="pagination-button"
          v-if="pg != '...'"
          @click="page = pg"
        >
          {{ pg }}
        </button>
        <div class="pagination-dots" v-else>...</div>
      </div>

      <button
        class="pagination-nextprev-button"
        v-if="page != totalPages"
        @click="page = page + 1"
      >
        {{ $t("directions.next") }}
      </button>
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar/Navbar";
import CardCategoryIcon from "../../components/ActiveGame/CardCategoryIcon";
import {
  getQuestionRids,
  getQuestionsByRids,
  getQueryRids,
} from "../../firebase/db";
import KuizbookSearch from "../../components/Kuizbook/KuizbookSearch.vue";

export default {
  data() {
    return {
      kuizs: ["O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O", "O"],
      questionRids: null,
      questions: null,
      selectedCategory: null,
      dataPerPage: 12,
      page: 1,
      intervalid1: null,
      searchLoading: false,
      queryRids: null,
    };
  },
  mounted() {
    this.getAllRids();
  },

  methods: {
    trimString(string, length) {
      let trimmedString =
        string.length > length
          ? string.substring(0, length - 3) + "..."
          : string.substring(0, length);

      return trimmedString;
    },

    async getAllRids() {
      this.questionRids = await getQuestionRids();
      this.questionRids.all = this.questionRids.all.sort(
        () => 0.5 - Math.random()
      );
    },

    async getQuestions() {
      this.questions = null;
      this.searchLoading = true;
      if (!this.questionRids) {
        await this.getAllRids();
      }
      if (!this.selectedCategory && !this.$route.query.search) {
        let pageRids = { ...this.questionRids };
        pageRids = [...pageRids.all]
          .reverse()
          .splice((this.page - 1) * this.dataPerPage, this.dataPerPage);
        const response = await getQuestionsByRids(pageRids);
        this.questions = response.results;
        this.searchLoading = false;
      } else if (this.$route.query.search) {
        let pageRids = [...this.queryRids];
        pageRids = pageRids
          .reverse()
          .splice((this.page - 1) * this.dataPerPage, this.dataPerPage);

        const response = await getQuestionsByRids(pageRids);
        this.questions = response.results;
        this.searchLoading = false;
      }
    },

    changeActiveHint(index) {
      this.questions[index].activeHintNumber < 3
        ? (this.questions[index].activeHintNumber = 2)
        : (this.questions[index].activeHintNumber = 0);
    },

    mouseleaveImage(index) {
      this.questions[index].activeHintNumber = 0;
    },

    goKuizInfo(k) {
      this.$router.push(`/kuiz/${k.answer.split(' ').join('-')}`);
    },
  },

  computed: {
    totalPages: function () {
      return this.questionRids
        ? Math.ceil(this.totalResults / this.dataPerPage)
        : 0;
    },

    totalResults: function () {
      if (this.questionRids) {
        if (!this.selectedCategory && !this.$route.query.search) {
          return this.questionRids.all.length;
        } else if (!this.selectedCategory && this.$route.query.search) {
          return this.queryRids.length;
        } else return 0;
      } else return 0;
    },

    pagesArray: function () {
      if (this.totalPages > 6) {
        if (this.page == 1) {
          return [
            this.page,
            this.page + 1,
            this.page + 2,
            "...",
            this.totalPages - 2,
            this.totalPages - 1,
            this.totalPages,
          ];
        } else if (this.page == 2) {
          return [
            this.page - 1,
            this.page,
            this.page + 1,
            "...",
            this.totalPages - 2,
            this.totalPages - 1,
            this.totalPages,
          ];
        } else if (this.page > this.totalPages - 3) {
          return [
            "...",
            this.totalPages - 2,
            this.totalPages - 1,
            this.totalPages,
          ];
        } else if (this.page == this.totalPages - 3) {
          return [
            this.page - 2,
            this.page - 1,
            this.page,

            this.totalPages - 2,
            this.totalPages - 1,
            this.totalPages,
          ];
        } else {
          return [
            this.page - 1,
            this.page,
            this.page + 1,
            "...",
            this.totalPages - 2,
            this.totalPages - 1,
            this.totalPages,
          ];
        }
      } else return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    },

    kuizInfo: {
      get() {
        return this.$store.state.kuizInfo;
      },
      set(value) {
        this.$store.commit("UPDATE_KUIZ_INFO", value);
      },
    },
  },

  beforeUnmount() {},
  components: {
    Navbar,
    CardCategoryIcon,
    KuizbookSearch,
  },

  watch: {
    page: {
      handler() {
        this.getQuestions();
      },
    },

    "$route.params": {
      immediate: true,
      async handler() {
        if (this.$route.query.search) {
          this.page = 1;
          this.queryRids = await getQueryRids(this.$route.query.search);

          this.getQuestions();
        } else {
          this.getQuestions();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.kuizbook-allpage {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 120px;
}
.kuizbook-wrapper {
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  padding: 12px;
}

.kuizs-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 2rem;
}

.kuizbook-title {
  font-size: 4rem;
  color: white;
  text-shadow: 0.15rem 0.15rem orange;
  font-weight: bold;
  display: flex;
  width: fit-content;
  word-break: keep-all;
  white-space: nowrap;
}

.line {
  display: flex;
  width: 100%;
  border-bottom: 3px solid orange;
  border-radius: 20px;
}
.kuizbook-subtitle {
  font-size: 1rem;
  color: orange;
  font-style: italic;
  margin: 8px;
}

.subtitle-ok {
  font-size: 1.7rem;
  color: white;
  text-shadow: 0.1rem 0.1rem orange;

  font-style: italic;
}

.words-wrapper {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 55vw;
  max-width: 1025px; /* 16/9 = 1.778 */
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */
  align-items: center;
  justify-items: center;
  margin: 2rem auto;
  gap: 1rem;
}

.question-hint-image-wrapper {
  display: flex;
  width: calc((55vw / 4) - 8px);
  height: calc(55vw * (2 / 5)); /* 100/56.25 = 1.778 */
  max-height: calc(1025px * (2 / 5));
  max-width: calc((1025px / 4) - 6px); /* 16/9 = 1.778 */
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5%;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 1px 4px rgba(0, 0, 0, 0.1) inset,
    -10px -200px 10px 1px rgba(255, 255, 255, 0.1) inset,
    10px 50px 20px 4px rgba(255, 255, 255, 0.1) inset,
    0 0 20px 5px rgba(0, 0, 0, 0.2);
  // background: url("~@/assets/images/diamonds.jpg");
  background-color: #1c091a;

  background: linear-gradient(135deg, #7e1a7575 50%, #55054ea6 90%) 0px 0/ 26px 26px,
    linear-gradient(130deg, #ab50e05d 25%, #0700072f 25%) 0px 0/ 26px 26px,
    linear-gradient(130deg, #d997ff8a 55%, #501150 95%) 0px 0/ 26px 26px,
    linear-gradient(90deg, #53015379 5%, #3d0639c0 15%) 14px 14px,
    linear-gradient(90deg, #530153 5%, #530153 15%) 14px 14px;

  border-radius: 8px;
  position: relative;
  background-size: 100% 100%;
  margin: auto;
  z-index: 1;
}
.question-hint-image-wrapper:hover {
  transition: 0.5s ease all;
  cursor: pointer;
  transform: scale(1.05);
}
.question-hint-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  z-index: 3;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: rgba(253, 224, 144, 0.15);
  box-shadow: 0px 5px 20px 20px rgba(253, 224, 144, 0.15);
  border-radius: 8%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 75%;
  max-height: 50%;
  object-fit: contain;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.question-hint-image-container {
  display: flex;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  padding: 4px;

  background: linear-gradient(135deg, #ffd25721 25%, transparent 25%) -4px 0/
      8px 8px,
    linear-gradient(145deg, rgba(255, 213, 77, 0.6) 25%, #ffa8268a 75%) -4px 0/
      8px 8px;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 1px 4px rgba(0, 0, 0, 0.1) inset, 0 0 20px 5px rgba(255, 255, 255, 0.1);
  background-size: 200% 200%;

  border-radius: 15%;
}

.question-hint-image-border {
  display: flex;
  position: absolute;
  z-index: 2;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  border-radius: 15%;
  background: url("~@/assets/images/golden-borders.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.5;
}
.question-hint-image-category {
  color: white;
  position: absolute;
  bottom: 10%;
}

.question-hint-title {
  position: absolute;
  top: 10%;
  display: flex;
  width: 60%;
  z-index: 7;
  font-size: 1.2rem;
  justify-content: center;
  text-align: center;
  color: white;
  text-transform: capitalize;
  text-shadow: 0rem 0rem 0.5rem yellow;
}

.question-hint-hint-name {
  position: absolute;
  bottom: 20%;
  display: flex;
  width: 70%;
  z-index: 7;
  font-size: 1rem;
  justify-content: center;
  text-align: center;
  color: whitesmoke;
  text-transform: capitalize;
  text-shadow: 0rem 0rem 0.1rem black;

  opacity: 0.5;
}

@keyframes rotate180y {
  from {
    transform: rotateY(-180deg);
  }
  to {
    transform: rotateY(0);
  }
}

@keyframes rotate180x {
  from {
    transform: rotateX(-180deg);
  }
  to {
    transform: rotateX(0);
  }
}

@keyframes backgroundPosition {
  0% {
    background-position: 0;
  }

  50% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0;
  }
}

.loading-hint-image-wrapper {
  display: flex;
  width: calc((55vw / 4) - 8px);
  height: calc(55vw * (2 / 5)); /* 100/56.25 = 1.778 */
  max-height: calc(1025px * (2 / 5));
  max-width: calc((1025px / 4) - 6px); /* 16/9 = 1.778 */
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5%;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 20px 5px rgba(0, 0, 0, 0.2);
  // background: url("~@/assets/images/diamonds.jpg");
  background-color: #1c091a;

  background: linear-gradient(135deg, #cf5fb5 25%, #911e76 75%);

  border-radius: 8px;
  position: relative;
  background-size: 106% 106%;
  margin: auto;
  z-index: 1;
}

.loading-hint-image-container {
  display: flex;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  padding: 4px;

  background: linear-gradient(135deg, #e9e3e3 25%, transparent 25%) -4px 0/ 8px
      8px,
    linear-gradient(225deg, #cccccc 25%, transparent 25%) -4px 0/ 8px 8px,
    linear-gradient(225deg, #ffffff 25%, #ffffff3b 25%) -4px 0/ 8px 8px,
    linear-gradient(315deg, #d8d8d8 25%, transparent 25%) 0px 0/ 8px 8px,
    linear-gradient(45deg, #d1d1d1 25%, rgb(207, 207, 207) 25%) 0px 0/ 8px 8px;
  background-size: 150% 150%;
  box-shadow: 0 0 10px 1px rgba(255, 166, 0, 0.7) inset,
    0 0 15vw 1px rgba(255, 166, 0, 0.2) inset;
  border-radius: 15%;
  animation: backgroundPosition 1s infinite;
}

@media only screen and (max-width: 1100px) {
  .words-wrapper {
    width: 100vw;
    max-width: calc(70vh * (5 / 2)); /* 16/9 = 1.778 */
    padding: 1px;
    top: 0;
    bottom: 0; /* vertical center */
    left: 0;
    right: 0; /* horizontal center */
    gap: 1px;
    grid-template-columns: 33% 34% 33%;
  }

  .question-hint-image-wrapper {
    width: calc(33vw - 1rem);
    height: calc(100vw * (2 / 4) - 1rem); /* 100/56.25 = 1.778 */
    max-height: 70vh;
    max-width: calc((70vh * (3.75 / 2) / 3) - 4px); /* 16/9 = 1.778 */
  }

  .loading-hint-image-wrapper {
    width: calc(33vw - 1rem);
    height: calc(100vw * (2 / 3.75) - 1rem); /* 100/56.25 = 1.778 */
    max-height: 70vh;
    max-width: calc((70vh * (3.75 / 2) / 3) - 4px); /* 16/9 = 1.778 */
  }

  .question-hint-hint-name {
    opacity: 1;
    bottom: 24%;
  }
}

//transition

.card-list-enter-active,
.card-list-leave-active {
  transition: all 0.5s ease-in;

  transform: translateX(5%);
}
.card-list-enter-from,
.card-list-leave-to {
  transition: all 0.5s cubic-bezier(0.29, 1.01, 1, -0.68);

  transform: rotateY(-180deg) translateX(-50px);
  opacity: 0;
}

//pagination
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 4vh;
  * {
    font-weight: bold;
  }
}

.pagination-button {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: white;
  margin: 0.2rem 0.5rem;
  border-radius: 8px;
  color: rgb(255, 156, 119);
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 1px 4px rgba(0, 0, 0, 0.1) inset,
    -10px -200px 10px 1px rgba(255, 255, 255, 0.1) inset,
    10px 50px 20px 4px rgba(255, 255, 255, 0.1) inset,
    0 0 20px 5px rgba(0, 0, 0, 0.2);
}

.pagination-nextprev-button {
  display: flex;
  width: 90px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  margin: 0.2rem 0.5rem;
  color: rgb(255, 156, 119);

  border-radius: 8px;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 1px 4px rgba(0, 0, 0, 0.1) inset,
    -10px -200px 10px 1px rgba(255, 255, 255, 0.1) inset,
    10px 50px 20px 4px rgba(255, 255, 255, 0.1) inset,
    0 0 20px 5px rgba(0, 0, 0, 0.2);
}

.pagination-dots {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: white;
  color: rgb(255, 156, 119);

  margin: 0.2rem 0.5rem;

  border-radius: 8px;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 1px 4px rgba(0, 0, 0, 0.1) inset,
    -10px -200px 10px 1px rgba(255, 255, 255, 0.1) inset,
    10px 50px 20px 4px rgba(255, 255, 255, 0.1) inset,
    0 0 20px 5px rgba(0, 0, 0, 0.2);
}

.pagination-button-selected {
  background-color: orangered;
  color: white;
}

@media screen and (max-width: 800px) {
  .pagination-button {
    height: 18px;
    width: 18px;
  }
  .pagination-nextprev-button {
    height: 20px;
    width: 50px;
  }
  .pagination-dots {
    height: 0;
    margin: 0 !important;
    padding: 0;
    display: none;
  }
}
</style>
