<template>
  <div v-if="user" class="userProfile" @click="isUserOptions = !isUserOptions">
    <div class="userProfile-user-profile">
      <img class="userProfile-user-profile-photo" v-if="user.profilePhoto" />
      <div class="userProfile-user-profile-photo" v-else>
        <Account :size="24"></Account>
      </div>
    </div>
    <div class="userProfile-user-name">
      {{ trimString(user.userName, 16) }}
    </div>

    <div v-if="isUserOptions" class="userProfile-options">
      <!-- <div class="userProfile-option" @click="signOutHandler">
      <button class="userProfile-sign-out-button" ><Cog class="flex justify-center align-center"></Cog></button>

             <div class="flex justify-center">{{$t('user.settings')}}</div> 

        </div> -->
      <div class="userProfile-option" @click="signOutHandler">
        <button class="userProfile-sign-out-button">
          <Logout class="flex justify-center align-center ml-1"></Logout>
        </button>

        <div class="flex justify-center">{{ $t("user.logout") }}</div>
      </div>
    </div>
  </div>
  <div v-else class="flex justify-center align-center">
    <router-link class="userProfile-login" to="/login">Login</router-link>
    <router-link class="userProfile-login" to="/register">Register</router-link>
  </div>
</template>

<script>
import Account from "vue-material-design-icons/Account.vue";
import Logout from "vue-material-design-icons/Logout.vue";

import { auth, signOut } from "../../firebase/db";

export default {
  data() {
    return {
      isUserOptions: false,
    };
  },

  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("UPDATE_USER", value);
      },
    },

    pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    },
  },

  methods: {
    signOutHandler() {
      //  const isAnon = auth.currentUser.isAnonymous
      //        if(isAnon){
      //          deleteUser(auth.currentUser).then((u) => {
      //            console.log(u)
      //   // User deleted.
      // }).catch((error) => {
      //   // An error ocurred
      //   // ...
      //   return error
      // });
      //        }

      this.pageLoading = true;

      signOut(auth)
        .then(() => {
          this.user = null;

          this.pageLoading = false;
        })
        .catch((error) => {
          this.pageLoading = false;
          console.log(error);
        });
      this.$router.push("/");
    },

    trimString(string, length) {
      let trimmedString =
        string.length > length
          ? string.substring(0, length - 3) + "..."
          : string.substring(0, length);

      return trimmedString;
    },
  },

  components: {
    Account,
    Logout,
  },
};
</script>

<style scoped lang="scss">
.userProfile {
  display: grid;
  width: 220px;

  border-radius: 24px;
  padding: 4px 8px;
  position: relative;
  cursor: pointer;
  height: 50px;
  background: rgba(105, 43, 133, 0.466);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1) inset,
    0 0 16px 1px rgba(0, 0, 0, 0.1) inset, 0 0 8px 1px rgba(255, 255, 255, 0.1);
  grid-template-columns: 20% 80%;
  transition: 0.5s all ease;
}

.userProfile:hover {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset,
    0 0 10px 1px rgba(0, 0, 0, 0.1) inset, 0 0 8px 1px rgba(255, 255, 255, 0.1),
    0 0 16px 1px rgba(255, 255, 255, 0.3);
}

.userProfile-user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.userProfile-user-profile-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: white;
  color: gray;
  font-size: 64px;
  border: 2px solid rgb(255, 204, 62);
  box-shadow: 0 0 5px 1px rgba(255, 166, 0, 0.205) inset,
    0 0 10px 2px rgba(255, 166, 0, 0.405) inset,
    0 0 5px 1px rgba(255, 166, 0, 0.105), 0 0 10px 2px rgba(255, 166, 0, 0.205);
}

.userProfile-user-name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: whitesmoke;
  text-shadow: 0 0 5px rgba(255, 166, 0, 0.836);
  padding: 0 6px;
}

.userProfile-sign-out-button {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  background: whitesmoke;
  border-radius: 50%;
  color: gray;
}

.userProfile-options {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background: rgb(103, 24, 139);

  padding: 8px;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
}

.userProfile-option {
  display: grid;
  grid-template-columns: 20% 80%;

  align-items: center;
  padding: 4px 8px;
  border-radius: 0.5rem;

  font-weight: bold;
  color: whitesmoke;
  text-shadow: 0 0 6px black;
  cursor: pointer;
  * {
    font-size: 16px !important;
  }
}
.userProfile-option:hover {
  background: rgba(255, 255, 255, 0.2);
}

.userProfile-login {
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  padding: 4px 8px;
  position: relative;
  text-shadow: 0.1rem 0.1rem rgba(255, 166, 0, 0.405);
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
  background: rgba(105, 43, 133, 0.466);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1) inset,
    0 0 16px 1px rgba(0, 0, 0, 0.1) inset, 0 0 8px 1px rgba(255, 255, 255, 0.1);
  margin: 0 4px;
  transition: 0.5s all ease;
}
.userProfile-login:hover {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset,
    0 0 10px 1px rgba(0, 0, 0, 0.1) inset, 0 0 8px 1px rgba(255, 255, 255, 0.1),
    0 0 16px 1px rgba(255, 255, 255, 0.3);
}
@media only screen and (max-width: 840px) {
  .userProfile {
    display: flex;
    width: 50px;

    justify-content: center;
    align-items: center;
  }

  .userProfile-login {
    display: none;
  }
  .userProfile-user-name {
    display: none;
  }

  .userProfile-options {
    position: absolute;
    left: -300%;
    top: 100%;
    width: 200px;
    background: rgb(103, 24, 139);

    padding: 8px;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
  }
}
</style>
