<template>
  <div class="create-lobby">
    <div class="home-index">
      <div class="home-index-text">
        {{ $t("home.desc-message") }}
      </div>
    </div>
    <Navbar></Navbar>
    <div v-if="!user" class="create-lobby-form slideFromLeftAnimation shadow-1">
      <router-link
        id="tag-login"
        class="btn btn-home slideFromLeftAnimation"
        to="/login"
      >
        {{ $t("home.login") }}
      </router-link>
      <router-link
        id="tag-register"
        class="btn btn-home slideFromLeftAnimation-delay-01"
        to="/register"
      >
        {{ $t("home.register") }}
      </router-link>
      <router-link
        id="tag-guest"
        class="-login btn btn-home slideFromLeftAnimation-delay-01"
        to="/guest-register"
      >
        {{ $t("home.guest-register") }}
      </router-link>
    </div>
    <div v-if="user" class="create-lobby-form slideFromLeftAnimation shadow-1">
      <router-link
        id="tag-new-game"
        class="-game btn btn-home slideFromLeftAnimation"
        to="/create-lobby"
      >
        {{ $t("home.new-game") }}
      </router-link>
      <router-link
        id="tag-lobbies"
        class="btn btn-home slideFromLeftAnimation-delay-01"
        to="/lobbies"
      >
        {{ $t("home.lobbies") }}
      </router-link>
    </div>

    <div
      id="adsgoeshere"
      class="google-ad-banner"
      style="max-height:120px;"
      v-html="adsenseContent"
      ref="adsenseDiv"
    ></div>
  </div>
    <MainFooter></MainFooter>

</template>

<script>
// @ is an alias to /src
import Navbar from "../components/Navbar/Navbar.vue";
import MainFooter from "../components/MainFooter.vue";

export default {
  title() {
    return "okuiz | Online quiz game that you can play with your friends, for free!";
  },
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("UPDATE_USER", value);
      },
    },

    isAdUnfilled:function(){
      return this.$refs.adsenseDiv
    }
  },

  data() {
    return {
      adsenseContent: "",
    };
  },
  mounted() {
  this.adsenseContent = document.getElementById('divadsensedisplaynone').innerHTML
  
  },

  components: {
    Navbar,MainFooter
  },
};
</script>
<style lang="scss" scoped>
.btn-home {
  margin-top: 24px;
  width: 70%;
  font-size: 24px;
  min-height: 50px !important;
  transition: 0.3s ease all;
}

.btn-home:hover {
  text-shadow: 2px 2px 0 rgb(161, 161, 161);
}
.home-index {
  position: absolute;
  bottom: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  .home-index-title {
    color: orange;
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 2px 2px white;
    animation: glow 0.5s cubic-bezier(0.86, 0, 0.07, 1) infinite;
  }

  .home-index-text {
    color: orange;
    font-size: 20px;
    font-weight: bold;
    text-shadow: 2px 2px white;
    animation: glow 0.5s cubic-bezier(0.86, 0, 0.07, 1) infinite;
    text-align: center;
  }
}
.create-lobby {
  padding: 120px 0;
}

.google-ad-banner{
  display:block;
  background: whitesmoke;
  text-align: center;
width:fit-content;
height: fit-content;
  margin-top: 20px;
}
.adsbygoogle{
  display:block;
  background: whitesmoke;
  text-align: center;
  width: 700px;
  max-width: 100%;
  height: 120px;
  max-height: 120px;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
}

@keyframes glow {
  0% {
    text-shadow: 0.05rem 0.05rem white;
  }
  25% {
    text-shadow: -0.05rem -0.05rem white, 0 0 10px gray;
  }
  50% {
    text-shadow: 0.05rem -0.05rem white, 0 0 10px gray;
  }

  75% {
    text-shadow: -0.05rem 0.05rem white, 0 0 10px gray;
  }

  100% {
    text-shadow: 0.05rem 0.2rem white, 0 0 10px #4dbbc7;
  }
}
</style>
