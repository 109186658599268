<template>
  <div class="setting-wrapper">
    <div class="setting-wrapper-title">
      {{ $t("game.categories") }}
    </div>
    <div
      class="select-category"
      @click="selectCategoryModal = !selectCategoryModal"
    >
      <div class="select-category-details">
        {{ localeSelectedCategories.toString() }}
      </div>
      {{ trimString(localeSelectedCategories.toString(), 21) }}
      <div class="ml-1" v-if="totalSelectedCategories">
        ({{ totalSelectedCategories }})
      </div>
    </div>
    <div class="select-category-modal-wrapper" v-if="selectCategoryModal">
      <div class="select-category-modal slideFromLeftAnimation">
        <div
          class="select-category-topic"
          v-for="(ctgs, name) in topics"
          :key="name"
        >
          <div class="select-category-topic-title" v-if="ctgs.length > 0">
            {{ $t(`categories.${name}`) }}
            <div class="ml-2">({{ topicTotalLength(ctgs) }})</div>
          </div>
          <div class="select-categories-wrapper">
            <button
              class="select-category-modal-option"
              v-for="category in ctgs.sort((a, b) => {
                return b.length - a.length;
              })"
              :key="category.name"
              @click="changeSelectedCategories(category.name)"
            >
              <div class="flex align-center mr-1" v-if="category.name != 'all'">
                <CheckboxBlankCircleOutline
                  style="color: white"
                  :size="16"
                  class="flex align-center"
                  v-if="!category.isChecked"
                ></CheckboxBlankCircleOutline>
                <CheckCircleOutline
                  class="flex align-center cl-success"
                  :size="16"
                  v-else
                ></CheckCircleOutline>
              </div>
              <div class="flex align-center mr-1" v-else>
                <CheckboxBlankCircleOutline
                  style="color: white"
                  :size="16"
                  class="flex align-center"
                  v-if="!isAllCategoriesChecked"
                ></CheckboxBlankCircleOutline>
                <CheckCircleOutline
                  class="flex align-center cl-success"
                  :size="16"
                  v-else
                ></CheckCircleOutline>
              </div>
              {{ $t(`categories.${category.name}`) }} ({{ category.length }})
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxBlankCircleOutline from "vue-material-design-icons/CheckboxBlankCircleOutline.vue";
import CheckCircleOutline from "vue-material-design-icons/CheckCircleOutline.vue";
import { getQuestionRids, updateLobbySettings } from "../../firebase/db";

export default {
  data() {
    return {
      selectCategoryModal: false,
      topics: {
        all: [],
        sports: [],
        "movies & tv series": [],
        games: [],
        history: [],
        geography: [],
        others: [],
      },
      allCategories: [],
      isAllCategoriesChecked: true,
    };
  },

  computed: {
    selectedCategories: function () {
      return this.settings.selectedCategories;
    },

    turnTime: function () {
      return this.settings.turnTime;
    },

    localeSelectedCategories: function () {
      let result = [];
      this.selectedCategories.forEach((c) => {
        result.push(this.$t(`categories.${c}`));
      });
      return result.toString();
    },

    totalSelectedCategories: function () {
      return this.settings.selectedCategories.length > 1
        ? this.settings.selectedCategories.length
        : null;
    },
  },

  mounted() {
    this.fetchQuestionRids();
    this.localeTurnTime = this.settings.turnTime ? this.settings.turnTime : 15;
  },
  methods: {
    async fetchQuestionRids() {
      const theQuestionRids = await getQuestionRids();
      for (const [key, value] of Object.entries(theQuestionRids)) {
        let topic = null;
        if (key == "tv series" || key == "movies" ||key == "actors") {
          topic = "movies & tv series";
        } else if (key == "all") {
          topic = "all";
        } else if (key == "games") {
          topic = "games";
        } else if (key == "historical characters") {
          topic = "history";
        } else if (key == "football" || key == "basketball") {
          topic = "sports";
        } else if (key == "cities" || key == "countries") {
          topic = "geography";
        } else {
          topic = "others";
        }
        this.topics[`${topic}`].push({
          name: key,
          length: value.length,
        });

        this.allCategories.push(key);

        this.topics = Object.fromEntries(
          Object.entries(this.topics).sort(([, a], [, b]) => {
          return  this.topicTotalLength(b) - this.topicTotalLength(a);
          })
        );
        this.checkSystem();
      }

      //   this.categories = this.categories.sort((a, b) => b.length - a.length);
    },

    async changeSelectedCategories(categoryName) {
      if (!this.userProfile.isAdmin) return;
      let setts = { ...this.settings };
      let allCats = [...this.allCategories];
      const theAllOne = allCats.findIndex((e) => e == "all");

      allCats.splice(theAllOne, 1);

      let isSelected = setts.selectedCategories.findIndex(
        (e) => e == categoryName
      );

      if (categoryName == "all" && setts.selectedCategories[0] != "all") {
        setts.selectedCategories = new Array();
        setts.selectedCategories.push("all");
      } else if (
        categoryName != "all" &&
        setts.selectedCategories[0] == "all"
      ) {
        const theCat = allCats.findIndex((e) => e == categoryName);

        allCats.splice(theCat, 1);

        setts.selectedCategories = [...allCats];
      } else {
        if (isSelected >= 0 && setts.selectedCategories.length > 1) {
          setts.selectedCategories.splice(isSelected, 1);
        } else if (setts.selectedCategories.length == 1 && isSelected >= 0) {
          return;
        } else {
          setts.selectedCategories.push(categoryName);
        }

        if (setts.selectedCategories.length == allCats.length) {
          setts.selectedCategories = new Array();
          setts.selectedCategories.push("all");
        }
      }


      await updateLobbySettings(`${this.$route.params.id}`, setts);
    },

    trimString(string, length) {
      let trimmedString =
        string.length > length
          ? string.substring(0, length - 3) + "..."
          : string.substring(0, length);

      return trimmedString;
    },

    checkSystem() {
      const checkAllCategories = () => {
        for (const [key, categories] of Object.entries(this.topics)) {
          categories.forEach((c) => (c.isChecked = true));
          key;
        }
      };
      const checkSelectedCategories = () => {
        for (const [key, categories] of Object.entries(this.topics)) {
          key;
          categories.forEach((c) => {
            let isThere = this.selectedCategories.find((ct) => {
              return ct == c.name;
            });
            if (isThere) {
              c.isChecked = true;
            } else {
              c.isChecked = false;
            }
          });
        }
      };
      if (this.selectedCategories[0] == "all") {
        checkAllCategories();
        this.isAllCategoriesChecked = true;
      } else {
        this.isAllCategoriesChecked = false;
        checkSelectedCategories();
      }
    },

    topicTotalLength(ctgs) {
      let result = 0;
      ctgs.forEach((c) => (result += c.length));
      return result;
    },
  },

  watch: {
    selectedCategories: {
      deep: true,
      immediate: true,
      handler() {
        this.checkSystem();
      },
    },
  },

  components: {
    CheckCircleOutline,
    CheckboxBlankCircleOutline,
  },

  props: {
    settings: Object,
    userProfile: Object,
  },
};
</script>

<style scoped lang="scss">
.setting-wrapper {
  display: flex;
  min-width: 16rem;

  flex-direction: column;
  position: relative;
  height: fit-content;
}
.setting-wrapper-title {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: whitesmoke;
  text-shadow: 0.15rem 0.15rem 0.1rem rgb(211, 144, 255);
  padding: 2px;
}
.select-category {
  display: flex;
  height: 3.3rem;
  padding: 12px;
  justify-content: center;
  align-items: center;
  background: rgb(218, 79, 236);

  border-radius: 1rem;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  color: whitesmoke;
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow: 1px 1px 1px black;
  box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.2),
    0 0 1px 2px rgba(0, 0, 0, 0.1) inset, 0 0 1px 4px rgba(0, 0, 0, 0.05) inset,
    0 0 1px 6px rgba(0, 0, 0, 0.03) inset;
  outline: none;
  border: none;
  width: 70%;
  margin: auto;
  text-align: center;
}

.select-category:hover {
  .select-category-details {
    display: flex;
  }
}

.select-category-details {
  display: none;
  position: absolute;
  justify-content: center;
  text-align: center;
  width: fit-content;
  word-break: break-word;
  max-width: 200px;
  top: 90%;
  left: 90%;
  background: rgb(60, 60, 90);
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  z-index: 5;
}

.select-category-modal-wrapper {
  position: absolute;
  top: 100%;
  left: 10%;
  display: flex;
  overflow: hidden !important;
  border-radius: 12px;

  width: 80%;
  height: 20rem;
  z-index: 2;
  
}
.select-category-modal {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  width: 100%;
  height: 100%;
  background: rgb(226, 112, 241);
  padding: 0.5rem;
  box-shadow: 0 0 0.3rem 2px rgba(0, 0, 0, 0.3),
    0 0 1px 2px rgba(0, 0, 0, 0.1) inset, 0 0 1px 4px rgba(0, 0, 0, 0.05) inset,
    0 0 1px 6px rgba(0, 0, 0, 0.03) inset;
  max-width: 80vw;
  align-content: flex-start;
  
}

.select-category-modal::-webkit-scrollbar-track {
  background: rgb(226, 112, 241);
}

.select-category-modal::-webkit-scrollbar {
  width: 0.5rem;

  background: rgb(226, 112, 241);
}

.select-category-modal::-webkit-scrollbar-thumb {
  border-radius: 12px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: rgb(200, 103, 224);
}

.select-category-topic {
  display: flex;
  flex-direction: column;
}
.select-category-topic-title {
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  width: 90%;
  margin: 4px auto;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 0.8rem;
  background: rgb(206, 59, 215);
  background: linear-gradient(
    273deg,
    rgba(206, 59, 215, 1) 0%,
    rgba(224, 90, 210, 1) 33%,
    rgba(199, 67, 181, 1) 100%
  );
  color: rgb(255, 255, 255);
  border-radius: 12px;
  text-transform: capitalize;
}
.select-category-modal-option {
  display: flex;
  width: fit-content;
  height: 2.4rem;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.8rem;
  border-radius: 12px;
  margin: 4px;
  background: rgb(255, 155, 242);
  text-transform: capitalize;
  color: rgb(255, 255, 255);
}

.select-category-modal-option-checkbox {
  margin: 2px;
}

.select-categories-wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
