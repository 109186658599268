<template>
  <Transition name="slide-fade">
    <div v-if="previousAnswer" class="previous-answer">
      {{ previousAnswer.answer }}
    </div>
  </Transition>
</template>

<script>
export default {


  computed: {
    previousAnswer: {
      get() {
        return this.$store.state.previousAnswer;
      },
      set(value) {
        this.$store.commit("UPDATE_PREVIOUS_ANSWER", value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.previous-answer {
  position: fixed;
  top: 10vh;
  display: flex;
  text-transform: uppercase;
  font-size: 4rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-style: italic;
  color: rgb(255, 80, 36);
  text-shadow: 0.15rem 0.15rem white, 0rem 0rem 10px rgb(250, 60, 35),
    0rem 0rem 10px rgb(168, 36, 19);
  z-index: 40;
}

.slide-fade-enter-active {
  transition: all 0.6s ease-out;
  transform: scale(1.1);
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateY(200%) scale(0.5);

  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateX(20px) scale(0.9);

  opacity: 0;
}
</style>
