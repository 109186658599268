import { firebaseConfig } from "./firebaseConfig";
import { initializeApp } from "firebase/app";
import store from "../store/index.js";
import {
  collection,
  addDoc,
  getFirestore,
  query,
  where,
  getDocs,
  getDoc,
  arrayUnion,
  updateDoc,
  doc,
  onSnapshot,
  setDoc,
  arrayRemove,
  deleteDoc,
  writeBatch,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  updateProfile,
  signOut,
  signInAnonymously,
} from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
// import i18n from '../i18n'
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, "notification_received");

const storage = getStorage();
const db = getFirestore();
const auth = getAuth();
//questions
const addDocument = async (col, question) => {
  try {
    const docRef = await addDoc(collection(db, col), question);

    return docRef.id;
  } catch (e) {
    return e;
  }
};

const getAllQuestions = async () => {
  const querySnapshot = await getDocs(collection(db, "questions"));
  const result = [];
  querySnapshot.forEach((doc) => {
    result.push(doc.data());
  });
  return result;
};

const listHints = async () => {
  const querySnapshot = await getDocs(collection(db, "questions"));
  const result = [];
  querySnapshot.forEach((doc) => {
    result.push(doc.data());
  });
  const allHints = [];
  result.forEach((q) => {
    q.hints.forEach((hint) => {
      let theHint = hint;
      let isName = allHints.find((ht) => {
        return ht.name === hint.name;
      });
      if (!isName) {
        allHints.push(theHint);
      }
    });
  });
  return allHints;
};

const pushRidsToGeneral = async (rid, category) => {
  const updateRef = doc(db, "general", "question-rids");
  const theObject = {};
  category = category.toLowerCase();
  theObject["all"] = arrayUnion(rid);
  theObject[category] = arrayUnion(rid);

  await updateDoc(updateRef, theObject);
};

const getQuestionRids = async () => {
  const docRef = doc(db, "general", "question-rids");
  const docSnap = await getDoc(docRef);

  return docSnap.exists() ? docSnap.data() : null;
};
//auth & users

const createUser = async (uid, user) => {
  try {
    await setDoc(doc(db, "users", uid), user);
  } catch (e) {
    return e;
  }
};

//lobbies

const addLobby = async (lobby) => {
  try {
    // const docRef = await addDoc(collection(db, "lobbies"), lobby);

    const docRef = await setDoc(doc(db, "lobbies", lobby._id), lobby);

    return docRef.id;
  } catch (e) {
    return e;
  }
};

const getCollection = async (col) => {
  const querySnapshot = await getDocs(collection(db, col));
  const postsArray = [];
  querySnapshot.forEach((doc) => {
    let post = new Object();
    post._id = doc.data()._id;
    post.isPassworded = doc.data().isPassworded;
    post.users = Array.apply(null, Array(doc.data().users.length)).map(
      function (x, i) {
        return i;
      }
    );
    post.userLimit = doc.data().userLimit;

    post.name = doc.data().name;
    post.createdAt = doc.data().createdAt;
    // doc.data() is never undefined for query doc snapshots
    postsArray.push(post);
  });

  return postsArray;
};
const getCollectionRealTime = async (col) => {
  onSnapshot(collection(db, col), (snapshot) => {
    console.log(snapshot.docs.map((doc) => doc.data()));
  });
};

const getData = async (col, qu, id) => {
  const theRef = collection(db, col);
  let result = null;
  // Create a query against the collection.
  const q = query(theRef, where(qu, "==", id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    result = doc.data();
  });
  if (result) {
    return result;
  } else {
    return;
  }
};

const addLobbyUser = async (id, user) => {
  const updateRef = doc(db, "lobbies", id);
  await updateDoc(updateRef, {
    users: arrayUnion(user),
  });
};

const lobbyRealTime = (id) => {
  const unsub = onSnapshot(doc(db, "lobbies", id), (docs) => {
    console.log(docs.data());
  });

  return unsub;
};

const getLobby = async (id) => {
  const docRef = doc(db, "lobbies", id);
  const docSnap = await getDoc(docRef);

  return docSnap.exists() ? docSnap.data() : null;
};

const updateLobbySettings = async (id, newSettings) => {
  const washingtonRef = doc(db, "lobbies", id);

  await updateDoc(washingtonRef, {
    settings: newSettings,
  });
};

//active game
const lobbyStartGame = async (id, questionSize) => {
  let isGameStarted = false;
  let startedAt = Date.now();
  let lobby = await getLobby(id);
  const theUser = lobby.users.find((user) => {
    let isUserIp =
      user.id == auth.currentUser.uid &&
      auth.currentUser.displayName == user.userName;

    return isUserIp;
  });
  let isNotReadyUser = lobby.users.find((user) => {
    return !user.isReady;
  });

  if (isNotReadyUser) {
    return;
  }
  if (theUser.isAdmin) {
    const activeGame = {};
    activeGame.users = lobby.users;
    activeGame.categories = lobby.settings.selectedCategories;
    activeGame.turnTime = lobby.settings.turnTime
      ? lobby.settings.turnTime
      : 15;
    activeGame.questions = await getQuestions(
      lobby.settings.selectedCategories,
      questionSize
    );
    activeGame.round = 1;
    activeGame.hintTurn = 1;
    activeGame.roundTimer = Math.round(
      startedAt + activeGame.turnTime * 1000 + 5000
    );

    (activeGame.isFinished = false), (activeGame.answers = []);
    await setDoc(doc(db, "activeGames", id), activeGame);

    const washingtonRef = await doc(db, "lobbies", id);

    await updateDoc(washingtonRef, {
      isStarted: true,
      startedAt: startedAt,
    });
    isGameStarted = true;
  } else {
    isGameStarted = false;
  }

  return isGameStarted;
};

const getQuestions = async (categories, length) => {
  let questionSize = length;
  let theRids = await getQuestionRids();
  let totalRids = [];

  categories.forEach((c) => {
    totalRids = totalRids.concat(theRids[`${c.toLowerCase()}`]);
  });
  if (questionSize > 30) {
    questionSize = 30;
  } else if (!questionSize) {
    questionSize = 20;
  }
  if (totalRids.length < questionSize) questionSize = totalRids.length;

  const shuffled = totalRids.sort(() => 0.5 - Math.random());
  let selected = shuffled.slice(0, questionSize);
  let result = [];

  for (let i = 0; i < selected.length; i++) {
    const q = query(
      collection(db, "questions"),
      where("rid", "==", selected[i])
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      let quest = doc.data();
      let secretAnswer = [];
      quest.answer
        .split("")
        .forEach((l) =>
          l != " " ? secretAnswer.push("*") : secretAnswer.push(" ")
        );
      quest.answer = secretAnswer.join("");
      quest.hints = quest.hints.sort(() => 0.5 - Math.random());
      result.push(quest);
    });
  }
  result = result.sort((a, b) => {
    a.rid - b.rid;
  });
  return result;
};

const nextTurn = async (id) => {
  const docRef = doc(db, "activeGames", id);

  const docSnap = await getDoc(docRef);
  const game = docSnap.data();
  let hintTurn = game.hintTurn;
  const turnTime = game.turnTime;
  let round = game.round;
  let totalRounds = game.questions.length;

  let isFinished = false;
  let roundTimer = game.roundTimer;
  if (hintTurn < 4) {
    hintTurn += 1;

    roundTimer = Math.round(Date.now() + turnTime * 1000);
  } else if (hintTurn == 4) {
    if (round < totalRounds) {
      hintTurn = 1;
      round += 1;
      //clear correct letters
      //

      roundTimer = Math.round(Date.now() + turnTime * 1000);
    } else {
      //end game
      const thelob = doc(db, "lobbies", id);
      const isStarted = false;
      store.commit("CLEAR_LETTER_OBJECT", new Array());

      await updateDoc(thelob, {
        isStarted,
      });
      isFinished = true;
    }
  }
  if (Date.now() - game.turnLastUpdated < 500) return;
  // console.log(Date.now() - game.turnLastUpdated, round, hintTurn);

  const washingtonRef = doc(db, "activeGames", id);

  await updateDoc(washingtonRef, {
    roundTimer,
    isFinished,
    hintTurn,
    round,
    turnLastUpdated: Date.now(),
  });
};

const answerQuest = async (id, answer, rid, userid) => {
  let result = {};
  const docRef = doc(db, "activeGames", id);
  const washingtonRef = doc(db, "activeGames", id);
  const batch = writeBatch(db);

  const docSnap = await getDoc(docRef);
  const game = docSnap.data();
  let hintTurn = game.hintTurn;
  let round = game.round;
  let users = game.users;
  let answers = game.answers;

  let question = null;
  let questionid = null;

  const q = query(collection(db, "questions"), where("rid", "==", rid));
  const theq = await getDocs(q);
  theq.forEach((doc) => {
    questionid = doc.id;
    question = doc.data();
  });
  let theAnswer = question.answer.split(" ").join("");

  let isAnsweredThisTurn = answers.find((a) => {
    return a.userid == userid && a.hintTurn == hintTurn && a.round == round;
  });

  let alreadyKnew = answers.find((a) => {
    return a.userid == userid && a.answer == theAnswer && a.round == round;
  });

  if (isAnsweredThisTurn) {
    result.code = 212;
    result.message = "You already answered this turn ⛔";
    store.commit("UPDATE_ANSWER_RESULT", result);
  } else if (alreadyKnew) {
    result.code = 999;
    result.message = "👍";
    store.commit("UPDATE_ANSWER_RESULT", result);
  } else {
    const answerObject = {
      userid,
      round,
      hintTurn,
      answer,
    };

    //user
    let userIndex = users.findIndex((u) => {
      return u.id == userid;
    });

    const questionReference = doc(db, "questions", questionid);

    //correct answer

    if (theAnswer == answer) {
      let deservedPoints = 500 - hintTurn * 100;

      result.code = 808;
      result.message = "✔️";
      result.deservedPoints = deservedPoints;
      store.commit("UPDATE_ANSWER_RESULT", result);

      users[userIndex].points += deservedPoints;

      // await updateDoc(washingtonRef, {
      //   users,
      // });
      batch.update(washingtonRef, { users });

      //answer object to answers array
      answers.push(answerObject);
      const userInteraction = question.userInteraction;
      userInteraction.correctAnswers += 1;
      await updateDoc(questionReference, {
        userInteraction,
      });
    } else {
      result.code = 707;
      result.message = "❌";
      store.commit("UPDATE_ANSWER_RESULT", result);

      //answer object to answers array
      answers.push(answerObject);

      const userInteraction = question.userInteraction;
      userInteraction.wrongAnswers += 1;
      await updateDoc(questionReference, {
        userInteraction,
      });
    }
    //correct letter indexes
    for (let j = 0; j < answer.length; j++) {
      if (theAnswer[j] == answer[j]) {
        const theCobject = {
          letter: theAnswer[j],
          index: j,
        };
        store.commit("PUSH_CORRECT_LETTER_OBJECT", theCobject);
      }
    }
    //
    // await updateDoc(washingtonRef, {
    //   answers,
    // });
    batch.update(washingtonRef, { answers });
    await batch.commit();

    const isEveryoneKnew = answers.filter((a) => {
      return a.answer == theAnswer && a.round == round;
    });
    const isEveryoneAnswered = answers.filter((a) => {
      return a.hintTurn == hintTurn && a.round == round;
    });

    const isEveryoneAnsweredOrKnew = function () {
      const isEA = [...isEveryoneAnswered];
      const isEK = [...isEveryoneKnew];
      isEK.forEach((ek) => {
        const thefindedIndex = isEA.findIndex((e) => {
          return (
            e.userid == ek.userid &&
            e.round == ek.round &&
            e.hintTurn == ek.hintTurn &&
            e.answer == ek.answer
          );
        });
        if (thefindedIndex > -1) {
          isEA.splice(thefindedIndex, 1);
        }
      });
      const resp = isEA.concat(isEK);
      return resp;
    };

    const theEAOK = isEveryoneAnsweredOrKnew();
    if (isEveryoneKnew.length >= game.users.length) {
      const hintTurn = 4;
      await updateDoc(washingtonRef, {
        hintTurn,
      });
      await nextTurn(id);
    } else if (theEAOK.length >= game.users.length) {
      await nextTurn(id);
    }
  }

  return result;
};

const getQuestion = async (rid) => {
  let question = [];
  rid = parseInt(rid);
  const q = query(collection(db, "questions"), where("rid", "==", rid));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    question.push(doc.data());
  });

  return question[0];
};

const getQuestionByAnswer = async (answer) => {
  let question = [];
  answer = answer.split("-").join(" ");
  const q = query(collection(db, "questions"), where("answer", "==", answer));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    question.push(doc.data());
  });

  return question[0];
};

const getQuestionsByRids = async (rids) => {
  let result = {
    results: [],
    totalResults: 0,
  };
  for (let i = 0; i < rids.length; i++) {
    let quesRef = null;

    quesRef = query(collection(db, "questions"), where("rid", "==", rids[i]));

    const querySnapshot = await getDocs(quesRef);
    querySnapshot.forEach((doc) => {
      let quest = doc.data();
      quest.activeHintNumber = 0;
      result.results.push(quest);
    });
  }
  return result;
};

const getQueryRids = async (querym) => {
  const result = [];
  let quesRef = query(
    collection(db, "questions"),
    where("answer", ">=", querym),
    where("answer", "<=", querym + "\uf8ff")
  );

  const querySnapshot = await getDocs(quesRef);
  querySnapshot.forEach((doc) => {
    let quest = doc.data();

    result.push(quest.rid);
  });

  return result;
};
//lobby user update
const assignAdmin = (id, user) => {
  const updateRef = doc(db, "lobbies", id);
  let newUser = { ...user };
  newUser.isAdmin = true;
  newUser.isReady = true;

  updateDoc(updateRef, {
    users: arrayUnion(newUser),
  });

  user.isAdmin = false;
  updateDoc(updateRef, {
    users: arrayRemove(user),
  });
};

const userIsReady = (id, user) => {
  const updateRef = doc(db, "lobbies", id);

  let newUser = { ...user };
  newUser.isReady = !user.isReady;
  updateDoc(updateRef, {
    users: arrayUnion(newUser),
  });

  updateDoc(updateRef, {
    users: arrayRemove(user),
  });
};

const kickUser = async (id, user) => {
  const updateRef = doc(db, "lobbies", id);
  let isAdmin = await checkIsAdmin(id);

  if (isAdmin) {
    updateDoc(updateRef, {
      kickedUsers: arrayUnion(user.id),

      users: arrayRemove(user),
    });
  }
};

const checkIsAdmin = async (lobbyId) => {
  let lobby = await getLobby(lobbyId);
  const theUser = lobby.users.find((user) => {
    let isUserIp =
      user.id == auth.currentUser.uid &&
      auth.currentUser.displayName == user.userName;

    return isUserIp;
  });

  return theUser.isAdmin;
};

//leave lobby

const leaveLobby = async (id, user) => {
  const updateRef = doc(db, "lobbies", id);

  await updateDoc(updateRef, {
    users: arrayRemove(user),
  });
};

//lobby chat
const lobbyChatSendMesage = async (id, message) => {
  const updateRef = doc(db, "lobbies", id);
  await updateDoc(updateRef, {
    chat: arrayUnion(message),
  });
};

//delete

const deleteCollection = async (col, id) => {
  await deleteDoc(doc(db, col, id));
};

//upload image
const uploadImage = async (file, filename) => {
  const storageRef = ref(storage, filename);
  // 'file' comes from the Blob or File API
  let result = await uploadBytes(storageRef, file).then(() => {
    return getDownloadURL(storageRef)
      .then((url) => {
        return url;
      })
      .catch((error) => {
        console.log(error);
      });
  });
  return result;
};
export {
  app,
  storage,
  db,
  doc,
  onSnapshot,
  addDocument,
  addLobby,
  getCollection,
  getData,
  addLobbyUser,
  getCollectionRealTime,
  lobbyRealTime,
  assignAdmin,
  leaveLobby,
  lobbyChatSendMesage,
  userIsReady,
  getLobby,
  auth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUser,
  updateProfile,
  signOut,
  checkIsAdmin,
  kickUser,
  signInAnonymously,
  deleteCollection,
  uploadImage,
  getAllQuestions,
  listHints,
  pushRidsToGeneral,
  getQuestionRids,
  getQuestionsByRids,
  getQuestion,
  updateLobbySettings,
  lobbyStartGame,
  nextTurn,
  answerQuest,
  getQueryRids,
  getQuestionByAnswer,
};
