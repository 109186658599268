<template>
  <h1 :class="valueChange ? 'value-change' : ''">{{ displayValue }}</h1>
</template>

<script>
import gsap from "gsap";
export default {
  name: "App",
  props: ["value"],
  components: {},
  watch: {
    value() {
      gsap.to(this, {
        tweenValue: this.value, duration: 2,
        onUpdate: () => {
          this.displayValue = Math.ceil(this.tweenValue);
          this.valueChange = true
          setTimeout(() => this.valueChange = false, 2000)
        },
      });
    },
  },
  data() {
    return {
      displayValue: this.value,
      tweenValue: this.value,
      valueChange:false,
    };
  },
};
</script>

<style scoped>
.value-change{
      color: rgb(62, 255, 36);
      transition:all 2s ease-in-out;
      transform: scale(1.3);

}
</style>