<template>
  <div class="active-game-users-wrapper" @click.self="leaderboard = null">
    <div class="active-game-users" v-if="leaderboard">
      <div class="active-game-users-title">
        <span>{{$t('game.leaderboard')}}</span>
        <button
          class="active-game-users-close-button"
          @click="leaderboard = null"
        >
          x
        </button>
      </div>
      <TransitionGroup name="list" tag="list" class="slideFromLeftAnimation">
        <ul
          class="active-game-user"
          v-for="(user) in placementLeaderboard"
          :key="user.id"
          :class="`placement-${user.placement}`"
        >
        <div class="leaderboard-placement">
            <div v-if="user.placement == 1">
                🥇
            </div>
            <div v-if="user.placement == 2">
                🥈
            </div>
            <div v-if="user.placement == 3">
                🥉
            </div>

            <span v-if="user.placement > 3">
                {{user.placement}}
            </span>
          </div>
          <div class="leaderboard-username">
            {{ user.userName }} <div class="ml-2" v-if="user.id == userid">({{$t('game.you')}})</div>
          </div>
          <div class="leaderboard-points">{{ user.points }} {{$t('game.points')}}</div>
        </ul>
      </TransitionGroup>
      <div class="active-game-users-bottom mt-auto">
        <button class="btn" @click="leaderboard = null">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLeaderboard: true,
    };
  },
  props:{
      userid:String
  },
  computed: {
    usersSortByPoints: function () {
      let result = [...this.leaderboard];
      result = result.sort((a, b) => {
        return b.points - a.points;
      });

      return result;
    },

    leaderboard: {
      get() {
        return this.$store.state.leaderboard;
      },
      set(value) {
        this.$store.commit("UPDATE_LEADERBOARD", value);
      },
    },

placementLeaderboard:function(){
      let leaderboard = [...this.usersSortByPoints];
      let placementboard = []
      let placement = 1;
      while (0 < leaderboard.length) {
        let maxpoint = Math.max.apply(
          Math,
          leaderboard.map(function (o) {
            return o.points;
          })
        );
        let maxpoints = leaderboard.filter((l) => {
          return l.points == maxpoint;
        });
        maxpoints.forEach((e) => {
          e.placement = placement;
          placementboard.push(e);
        });
        leaderboard.splice(0, maxpoints.length);
        placement++;
      }
      return placementboard
}
},

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.active-game-users-wrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}
.active-game-users {
  display: flex;
  flex-direction: column;
  color: white;
  width: 1200px;
  max-width: 98%;
  height: 90vh;
  max-height: 100%;
  font-size: 24px;
  user-select: none;
  z-index: 90;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1rem;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
    text-shadow: 0.15rem 0.15rem rgb(150, 150, 150);

}

.active-game-users:hover {
  opacity: 1;
}

.active-game-user {
  display: flex;
  padding: 1rem 2rem;
}

.leaderboard-placement{
     font-size: 2rem;
  display: flex;
  width: 3rem;
  height: 3rem;
  background: orange;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 3rem;
  font-size: 2rem;
  div{
      font-size: 2rem;
  }
}

.placement-1{
    background: rgba(255, 102, 92, 0.7);
    text-shadow: 0.15rem 0.15rem rgb(60, 60, 60);
}
.placement-2{
    background: rgba(255, 102, 92, 0.5);
    text-shadow: 0.15rem 0.15rem rgb(90, 90, 90);

}
.placement-3{
    background: rgba(255, 102, 92, 0.3);
    text-shadow: 0.15rem 0.15rem rgb(120, 120, 120);

}
.leaderboard-username {
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.leaderboard-points {
  font-size: 2rem;
  margin-left: auto;
}
.active-game-users-title {
  display: flex;
  justify-content: space-between;
  background: orange;
  padding: 1rem 2rem;
  span {
    font-size: 1.5rem !important;
  }
}
.active-game-users-bottom {
  display: flex;
  width: 100%;
  justify-content: center;
  background: orange;
  padding: 1rem 2rem;
  .btn {
    background: whitesmoke;
    color: gray;
  }
}
.active-game-users-close-button {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: orange;
  color: white;
  border-radius: 50%;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
