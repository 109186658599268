<template>
  <div>
    <div  class="navbar-elements">
      <router-link to="/lobbies" class="navbar-element">
        {{ $t("home.lobbies") }}
      </router-link>
      <!-- <router-link to="/how-to-play" class="navbar-element">
        {{ $t("home.how-to-play") }}
      </router-link> -->

      <router-link to="/kuizbook" class="navbar-element">
        Kuizbook
      </router-link>
    </div>
    <button @click="isElementsModal = !isElementsModal">
      <div  class="navbar-elements-togglebar">
        <Menu></Menu>
      </div>
    </button>

    <div class="navbar-elements-mobile" v-if="isElementsModal">
        <div @click="isElementsModal = !isElementsModal" class="navbar-elements-mobile-wrapper">
       <router-link to="/lobbies" class="navbar-element-mobile ">
        {{ $t("home.lobbies") }}
      </router-link>
      <!-- <router-link to="/how-to-play" class="navbar-element-mobile ">
        {{ $t("home.how-to-play") }}
      </router-link> -->

      <router-link to="/kuizbook" class="navbar-element-mobile ">
        Kuizbook
      </router-link>
        </div>
      
    </div>
  </div>
</template>

<script>
import Menu from "vue-material-design-icons/Menu.vue";

export default {

    data(){
        return{
            isElementsModal:false
        }
    },
  components: {
    Menu,
  },
};
</script>

<style lang="scss" scoped>
.navbar-elements {
  position: absolute;
  display: flex;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.navbar-element {
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-shadow: 0.1rem 0.1rem rgba(255, 166, 0, 0.405),
    10px 10px 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding: 8px 16px;
  transition: all 0.5s ease;
}
.navbar-element:hover {
  text-shadow: 0.1rem 0.1rem rgba(255, 166, 0, 0.405),
    -5px 10px 10px rgba(0, 0, 0, 0.5);
}

.navbar-elements-mobile{
      position: absolute;
  display: flex;
  right: 0%;
  top: 110%;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
 
  overflow: hidden;
}

.navbar-elements-mobile-wrapper{
  background: rgb(103, 24, 139);
border-radius: 12px;
}


.navbar-element-mobile {
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-shadow: 0.1rem 0.1rem rgba(255, 166, 0, 0.405),
    10px 10px 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding: 8px 16px;
  transition: all 0.5s ease;
  width: 300px;
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;

}
.navbar-element-mobile:hover {
  text-shadow: 0.1rem 0.1rem rgba(255, 166, 0, 0.405),
    -5px 10px 10px rgba(0, 0, 0, 0.5);
}


.navbar-elements-togglebar {
  display: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(105, 43, 133, 0.466);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1) inset,
    0 0 16px 1px rgba(0, 0, 0, 0.1) inset, 0 0 8px 1px rgba(255, 255, 255, 0.1);
  margin-left: auto;
  color: rgb(255, 255, 255);
  * {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
  transition: 0.5s all ease;
}

@media only screen and (max-width: 1000px) {
  .navbar-elements {
    display: none;
  }

  .navbar-elements-togglebar {
    display: flex;
  }
}
</style>
