<template>
  <div>
    <Navbar></Navbar>
        <a class="back-button"
         @click="$router.go(-1)"
>
      <ArrowLeft :size="32"></ArrowLeft>
    </a>
    <div class="how-to-play-wrapper" >
      
      <div class="flex align-center justify-center" v-if="kuiz">
        <div class="line"></div>

        <h1 class="how-to-play-title" >
          {{ kuiz.answer }}
        </h1>
        <div class="line"></div>
      </div>

      <h5 class="how-to-play-subtitle" v-if="kuiz">
        <div class="subtitle-ok">
-{{ $t(`categories.${kuiz.category.toLowerCase()}`) }}
        </div>
      </h5>
     
    </div>
      <TransitionGroup
        name="card-list"
        tag="div"
        class="words-wrapper"
        v-if="kuiz"
      >
        <div
          class="question-hint-image-wrapper"
          v-for="(image, index) in kuiz.hints"
          :key="index"
     
        >
          <div class="question-hint-image-border"></div>
          <img
            class="question-hint-image"
            :src="image.url"
          />
          <div class="question-hint-image-container"></div>
          <div class="question-hint-title">
            {{ image.name }}
          </div>
        
        </div>
      </TransitionGroup>
  </div>
</template>

<script>
import Navbar from "../../components/Navbar/Navbar";
import { getQuestionByAnswer } from "../../firebase/db";
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";

export default {
  data() {
    return {
      kuiz: null,
    };
  },
  mounted() {
    this.getQuestion();
  },
  methods: {
    async getQuestion() {
      const id = this.$route.params.id.split('-').join(' ')
    
      this.kuiz = await getQuestionByAnswer(id);


    },
  },
  components: { Navbar,ArrowLeft },
};
</script>

<style lang="scss" scoped>

.back-button {
  position: absolute;
  top: 110px;
  left: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  color: white;
  background: orange;
  z-index: 99;
  cursor: pointer;

  .material-design-icon {
    * {
      height: 2.5rem;
      width: 2.5rem;
    }

  }
}
.how-to-play-wrapper {
  width: 1025px;
  max-width: 100%;
  margin: 120px auto 3rem auto;
  padding-top: 12px;
  position: relative;
}

.how-to-play-title {
  font-size: 4rem;
  color: white;
  text-shadow: 0.15rem 0.15rem orange;
  font-weight: bold;
  display: flex;
  width: fit-content;
  word-break: keep-all;
  white-space: nowrap;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.line {
  display: flex;
  width: 100%;
  border-bottom: 3px solid orange;
  border-radius: 20px;
}
.how-to-play-subtitle {
  font-size: 1.5rem;
  color: orange;
  font-style: italic;
  margin: 8px;
}

.subtitle-ok {
  font-size: 1.7rem;
  color: white;
  text-shadow: 0.1rem 0.1rem orange;
  letter-spacing: 2px;

  font-style: italic;
}



.words-wrapper {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 70vw;
  max-width: 1025px; /* 16/9 = 1.778 */
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */
  align-items: center;
  justify-items: center;
  margin: 1rem auto;
}

.question-hint-image-wrapper {
  display: flex;
  width: calc((55vw / 4) - 12px);
  height: calc(55vw * (2 / 5)); /* 100/56.25 = 1.778 */
  max-height: calc(1025px * (2 / 5));
  max-width: calc((1025px / 4) - 12px); /* 16/9 = 1.778 */
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5%;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 1px 4px rgba(0, 0, 0, 0.1) inset,
    -10px -200px 10px 1px rgba(255, 255, 255, 0.1) inset,
    10px 50px 20px 4px rgba(255, 255, 255, 0.1) inset,
    0 0 20px 5px rgba(0, 0, 0, 0.2);
  // background: url("~@/assets/images/diamonds.jpg");
  background-color: #1c091a;

  background: linear-gradient(135deg, #7e1a7575 50%, #55054ea6 90%) 0px 0/ 26px 26px,
    linear-gradient(130deg, #ab50e05d 25%, #0700072f 25%) 0px 0/ 26px 26px,
    linear-gradient(130deg, #d997ff8a 55%, #501150 95%) 0px 0/ 26px 26px,
    linear-gradient(90deg, #53015379 5%, #3d0639c0 15%) 14px 14px,
    linear-gradient(90deg, #530153 5%, #530153 15%) 14px 14px;

  border-radius: 8px;
  position: relative;
  background-size: 100% 100%;
  z-index: 1;
}
.question-hint-image-wrapper:hover {
  transition: 0.5s ease all;
  cursor: pointer;
  transform: scale(1.05);
}
.question-hint-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  z-index: 3;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background: rgba(253, 224, 144, 0.15);
  box-shadow: 0px 5px 20px 20px rgba(253, 224, 144, 0.15);
  border-radius: 8%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 75%;
  max-height: 50%;
  object-fit: contain;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.question-hint-image-container {
  display: flex;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  padding: 4px;

  background: linear-gradient(135deg, #ffd25721 25%, transparent 25%) -4px 0/
      8px 8px,
    linear-gradient(145deg, rgba(255, 213, 77, 0.6) 25%, #ffa8268a 75%) -4px 0/
      8px 8px;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 1px 4px rgba(0, 0, 0, 0.1) inset, 0 0 20px 5px rgba(255, 255, 255, 0.1);
  background-size: 200% 200%;

  border-radius: 15%;
}

.question-hint-image-border {
  display: flex;
  position: absolute;
  z-index: 2;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  border-radius: 15%;
  background: url("~@/assets/images/golden-borders.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.5;
}
.question-hint-image-category {
  color: white;
  position: absolute;
  bottom: 10%;
}

.question-hint-title {
  position: absolute;
  top: 10%;
  display: flex;
  width: 60%;
  z-index: 7;
  font-size: 1.2rem;
  justify-content: center;
  text-align: center;
  color: white;
  text-transform: capitalize;
  text-shadow: 0rem 0rem 0.5rem yellow;
}



@keyframes rotate180y {
  from {
    transform: rotateY(-180deg);
  }
  to {
    transform: rotateY(0);
  }
}

@keyframes rotate180x {
  from {
    transform: rotateX(-180deg);
  }
  to {
    transform: rotateX(0);
  }
}

@media only screen and (max-width: 1100px) {
  .words-wrapper {
    width: 100vw;
    max-width: calc(70vh * (5 / 2)); /* 16/9 = 1.778 */
    top: 0;
    bottom: 0; /* vertical center */
    left: 0;
    right: 0; /* horizontal center */
    grid-template-columns: 25% 25% 25% 25%;
    padding: 1px;
  }

  .question-hint-image-wrapper {
    width: calc(25vw - 1rem);
    height: calc(100vw * (2 / 5) - 1rem); /* 100/56.25 = 1.778 */
    max-height: 70vh;
    max-width: calc((70vh * (5 / 2) / 4) - 4px); /* 16/9 = 1.778 */
  }

  .question-hint-hint-name {
    opacity: 1;
    bottom: 24%;
  }
}
</style>
