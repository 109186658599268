<template>
  <div class="create-lobby">
    <Navbar></Navbar>

   
    <div class="lobbies-title">
      {{ $t("lobbies.lobbies") }}
    </div>
    <div class="lobbies-filters shadow-1">
      <button @click="getLobbies" class="btn btn-md">
        <Refresh :size="20" class="mr-2"></Refresh>
        {{ $t("lobbies.refresh") }}
      </button>
      <div class="">
        <input
          class="lobbies-filters-search"
          type="text"
          :placeholder='`${$t("lobbies.search")}...`'
          v-model="searchWord"
          @input="filterLobbies"
        />
      </div>

      <router-link to="create-lobby" class="btn btn-md">
        {{ $t("lobbies.create-lobby") }}
      </router-link>
    </div>
    <div class="lobbies-wrapper shadow-1">
      <TransitionGroup name="list" class="lobbies" tag="div">
        <router-link
          class="lobby"
          :to="`/game/${lobby._id}`"
          v-for="(lobby, index) in filteredLobbies"
          :key="index"
        >
          <div class="lobby-title">
            {{ lobby.name }}
          </div>
          <div class="lobby-user-limit">
            {{ lobby.users.length }}/{{ lobby.userLimit }}
          </div>
          <div></div>

          <div>
            <EyeOffOutline v-if="!lobby.isPassworded"></EyeOffOutline>
            <EyeOutline v-if="lobby.isPassworded"></EyeOutline>
          </div>
        </router-link>

        <div
          class="flex justify-center align-center cl-light"
          v-if="filteredLobbies.length == 0"
        >
          {{ $t("lobbies.no-lobby") }}
        </div>
      </TransitionGroup>
    </div>
    <div class="lobbies-infos shadow-1">
      <div v-if="lobbies" class="btn">
        {{ $t("lobbies.total-lobbies") }}: {{ lobbies.length }}
      </div>

      <div class="btn"> {{ $t("lobbies.users-playing") }}: {{ totalUsers }}</div>
    </div>
  </div>
</template>

<script>
import { getCollection } from "../firebase/db";
import Refresh from "vue-material-design-icons/Refresh.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import Navbar from "../components/Navbar/Navbar.vue";

export default {
  data() {
    return {
      lobbies: null,
      filteredLobbies: [],
      searchWord: "",
    };
  },

  title() {
    return `Lobbies | okuiz `;
  },

  mounted() {
    this.getLobbies();
    this.checkUser();
  },

  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("UPDATE_USER", value);
      },
    },
    totalUsers: function () {
      if (this.lobbies) {
        let total = 0;
        this.lobbies.forEach((lobb) => {
          total += lobb.users.length;
        });

        return total;
      } else {
        return 0;
      }
    },
  },

  methods: {
    checkUser() {
      if (!this.user) {
        this.$router.push("/");
      }
    },

    async getLobbies() {
      let lobs = await getCollection("lobbies");
      this.lobbies = lobs.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
      this.filteredLobbies = this.lobbies.filter((word) => {
        let wordName = word.name.toLowerCase();
        return wordName.includes(this.searchWord);
      });
    },

    filterLobbies() {
      this.filteredLobbies = this.lobbies.filter((word) => {
        let wordName = word.name.toLowerCase();
        return wordName.includes(this.searchWord);
      });
    },
  },
  components: {
    Refresh,
    EyeOffOutline,
    EyeOutline,Navbar
  },

  watch: {
    totalUsers: {
      immediate: true,
      handler() {
        this.title = `Lobbies | okuiz | Total Lobbies: ${
          this.lobbies ? this.lobbies.length : 0
        } | Users Playing ${this.totalUsers ? this.totalUsers : 0}`;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.lobbies-wrapper {
  display: flex;
  flex-direction: column;
  height: 600px;
  max-height: 65vh;
  width: 700px;
  max-width: 95%;
  align-items: center;
  background: skyblue;
  border-radius: 4%;
  overflow: hidden;
}
.lobbies-title {
  font-size: 36px;
  font-weight: bold;
  color: white;
  padding: 12px;
  margin-top: 70px;
}
.lobbies-filters {
  display: flex;
  background: rgb(148, 219, 247);
  flex-wrap: wrap;
  width: 540px;
  max-width: 80%;
  align-items: center;
  justify-content: space-around;

  padding: 0.8rem;
  border-radius: 0.8rem 0.8rem 0 0;
  color: white;
}

.lobbies-filters-search {
  padding: 6px 0.8rem;
  border: none;
  border-radius: 0.8rem;
  margin:0.6rem;
}
.lobbies-infos {
  display: flex;
  background: rgb(148, 219, 247);
  flex-wrap: wrap;
  width: 540px;
  max-width: 80%;
  justify-content: space-around;
  padding: 12px;
  border-radius: 0 0 12px 12px;
  color: white;
}
.lobbies {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  padding: 24px;
}
.lobby {
  display: grid;
  grid-template-columns: 46% 18% 18% 18%;
  width: 100% !important;
  background: rgb(168, 221, 241);
  padding: 12px;
  border-radius: 12px;
  margin: 4px;
  cursor: pointer;
  color: white;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.lobby:hover {
  opacity: 0.9;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  filter: brightness(1.4);

  opacity: 0;
  transform: translateX(-30px);
}

.lobbies::-webkit-scrollbar-track {
  background-color: rgb(162, 227, 253);
}

.lobbies::-webkit-scrollbar {
  width: 12px;

  background-color: skyblue;
}

.lobbies::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: rgb(120, 173, 194);
}
</style>
