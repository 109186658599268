import { createI18n } from "vue-i18n/index";

const readyLanguages = ["tr", "en"];

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

let language = window.navigator.userLanguage || window.navigator.language;
language = language.split("-")[0];
let isLang = readyLanguages.find((l) => {
  return l == language;
});
if (isLang && !localStorage.getItem("lang")) {
  localStorage.setItem("lang", language);
}

let lang = localStorage.getItem("lang") || "en";

let isGetItemLang = readyLanguages.find((l) => {
  return l == lang;
});
if(!isGetItemLang){
  lang = 'en'
}


const i18n = createI18n({
  locale: lang || "en",
  fallbackLocale: lang || "en",
  messages: loadLocaleMessages(),
});

export default i18n;

