<template>
  <div class="active-game" v-if="game" @keyup.enter="answer()">
    <button class="hidden" ref="looseFocus"></button>
    <div v-if="!isUserPlaying" class="active-game-spectating">
      <EyeOutline class="flex align-center justify-center mr-2"></EyeOutline>

      {{ $t("game.you-spectating") }}
    </div>
    <PreviousAnswer></PreviousAnswer>
    <CardModal
      v-if="modalActiveHint && question"
      :activeHint="modalActiveHint"
      :questionCategory="question.category"
      @isCardModal="closeCardModal"
    ></CardModal>
    <div
      class="question-timer"
      :class="lessThanFiveSeconds ? 'bg-error shake-animation' : ''"
      v-if="game"
    >
      {{
        Math.round(
          (game.roundTimer - now) / 1000 > game.turnTime ||
            0 > (game.roundTimer - now) / 1000
        )
          ? game.turnTime
          : Math.round((game.roundTimer - now) / 1000)
      }}
    </div>
    <ActiveGameUsers
      :users="game.users"
      :userid="userProfile.id"
    ></ActiveGameUsers>
    <div class="active-round">
      {{ activeRound }}/{{ game.questions.length }}
    </div>
    <Transition name="answer-result-transition">
      <div
        v-if="answerResult"
        class="question-answer-result"
        :class="`code-${answerResult.code}`"
      >
        {{ $t(`answer-results.${answerResult.code}`) }}
        <div
          v-if="answerResult.deservedPoints"
          class="question-answer-result-points"
        >
          +{{ answerResult.deservedPoints }}
        </div>
      </div>
    </Transition>

    <TransitionGroup name="card-list" tag="div" class="words-wrapper">
      <div
        class="question-hint-image-wrapper"
        v-for="activeHint in activeHints"
        :key="activeHint.url"
        @click="modalActiveHint = activeHint"
      >
        <div class="question-hint-image-border"></div>
        <img class="question-hint-image" :src="activeHint.url" />

        <div class="question-hint-image-container"></div>

        <CardCategoryIcon
          :category="question.category"
          class="question-hint-image-category"
        ></CardCategoryIcon>
      </div>
    </TransitionGroup>
    <div v-if="question" class="question-language">
      <div class="flex align-center" v-if="question.language != 'none'">
        <Translate
          class="flex align-center justify-center"
          :size="14"
        ></Translate>
        <!-- {{ $t("languages.language") }}: -->
        :
        {{ $t(`languages.${question.language}.name`) }}
      </div>
    </div>
    <TransitionGroup
      name="list"
      tag="div"
      v-if="activeRound"
      class="answer-wrapper"
    >
      <div
        v-for="(sentence, index) in splitAnswer"
        :key="index"
        class="answer-sentence-wrapper"
      >
        <div
          v-for="(letter, index2) in sentence"
          :key="index2"
          class="answer-sentence-letter"
          :class="
            checkCorretLetters(splitAnswerIndex(index, index2))
              ? 'correct-letter-background'
              : ''
          "
        >
          <div>{{ writtenArray[index][index2] }}</div>
          <div
            v-if="checkCorretLetters(splitAnswerIndex(index, index2))"
            class="correct-letter-wrapper"
          >
            {{ checkCorretLetters(splitAnswerIndex(index, index2)) }}
          </div>
        </div>
      </div>
    </TransitionGroup>

    <Keyboard
      @clickedLetter="keyDownHandler"
      @answerQuestion="answer"
    ></Keyboard>
    <div class="active-game-chat">
      <LobbyChat
        @focusChat="chatFocs"
        :userProfile="userProfile"
        :chat="chat"
        :lobbyId="lobbyId"
        :isModal="false"
      ></LobbyChat>
    </div>
  </div>
</template>

<script>
import {
  db,
  doc,
  onSnapshot,
  nextTurn,
  answerQuest,
  getQuestion,
} from "../../firebase/db";
import Keyboard from "./Keyboard.vue";
import ActiveGameUsers from "./ActiveGameUsers.vue";
import CardModal from "./CardModal.vue";

import CardCategoryIcon from "./CardCategoryIcon.vue";
import LobbyChat from "../LobbyChat.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import Translate from "vue-material-design-icons/Translate.vue";

import PreviousAnswer from "./PreviousAnswer.vue";

export default {
  data() {
    return {
      game: null,
      splitAnswer: [],
      writtenAnswer: "",
      answerTotalLength: 0,
      now: 0,
      lessThanFiveSeconds: false,
      chatFocus: false,
      modalActiveHint: null,
      answerCooldown: false,
    };
  },
  emits: ["focusChat", "clickedLetter", "answerQuestion"],

  mounted() {
    this.getGameRealTime();
    window.addEventListener("keydown", this.keyDownHandler);

    this.updateNow();
    this.nowInterval = setInterval(this.updateNow.bind(this), 1000);
  },

  beforeUnmount() {
    this.getGameRealTime();
    window.removeEventListener("keydown", this.keyDownHandler);
    clearInterval(this.nowInterval);
  },

  props: {
    userProfile: Object,
    chat: Array,
    lobbyId: String,
  },

  computed: {
    pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    },
    correctLetterObjects: {
      get() {
        return this.$store.state.correctLetterObjects;
      },
      set(value) {
        this.$store.commit("CLEAR_LETTER_OBJECT", value);
      },
    },

    answerResult: {
      get() {
        return this.$store.state.answerResult;
      },
      set(value) {
        this.$store.commit("UPDATE_ANSWER_RESULT", value);
      },
    },

    question: function () {
      return this.game.questions[this.game.round - 1];
    },
    hintTurn: function () {
      return this.game ? this.game.hintTurn : null;
    },

    activeHints: function () {
      return this.question.hints.slice(0, this.hintTurn);
    },

    activeRound: function () {
      return this.game ? this.game.round : null;
    },

    writtenArray: function () {
      let result = [];
      let written = this.writtenAnswer;
      let totalLetters = 0;
      this.splitAnswer.forEach((sentence) => {
        result.push(
          written.slice(totalLetters, totalLetters + sentence.length)
        );
        totalLetters += sentence.length;
      });
      return result;
    },

    roundTimer: function () {
      return this.game ? this.game.roundTimer : null;
    },

    isUserPlaying: function () {
      return this.game.users.find((user) => {
        return this.userProfile.id == user.id;
      });
    },
    isGameFinished: function () {
      return this.game ? this.game.isFinished : false;
    },

    previousAnswer: {
      get() {
        return this.$store.state.previousAnswer;
      },
      set(value) {
        this.$store.commit("UPDATE_PREVIOUS_ANSWER", value);
      },
    },
  },

  methods: {
    updateNow() {
      this.now = Math.round(Date.now());
    },
    getGameRealTime() {
      onSnapshot(doc(db, "activeGames", `${this.$route.params.id}`), (docs) => {
        this.game = docs.data();
      });
    },

    answerSplit() {
      this.splitAnswer =
        this.game.questions[this.game.round - 1].answer.split(" ");
      this.answerTotalLength = this.splitAnswer.join("").length;
      for (let i = 0; i < this.splitAnswer.length; i++) {
        this.splitAnswer[i] = this.splitAnswer[i].split("");
      }
    },

    keyDownHandler(e) {
      if (this.chatFocus) return;

      let alphabet =
        "abcdefghijklmnopqrstuvwxyz'1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(
          ""
        );
      if (this.writtenAnswer.length < this.answerTotalLength) {
        let isLetter = alphabet.find((element) => element == e.key);
        if (isLetter) {
          this.writtenAnswer += e.key.toLowerCase();
        } else if (
          e.key == "İ" ||
          e.key.toLowerCase() == "ı" ||
          e.key.toLowerCase() == "ï" ||
          e.key.toLowerCase() == "î"
        ) {
          this.writtenAnswer += "i";
        } else if (e.key.toLowerCase() == "ş") {
          this.writtenAnswer += "s";
        } else if (e.key.toLowerCase() == "ç") {
          this.writtenAnswer += "c";
        } else if (
          e.key.toLowerCase() == "ó" ||
          e.key.toLowerCase() == "õ" ||
          e.key.toLowerCase() == "ô" ||
          e.key.toLowerCase() == "ö"
        ) {
          this.writtenAnswer += "o";
        } else if (
          e.key.toLowerCase() == "ä" ||
          e.key.toLowerCase() == "ã" ||
          e.key.toLowerCase() == "à" ||
          e.key.toLowerCase() == "â"
        ) {
          this.writtenAnswer += "a";
        } else if (e.key.toLowerCase() == "í") {
          this.writtenAnswer += "i";
        } else if (
          e.key.toLowerCase() == "é" ||
          e.key.toLowerCase() == "è" ||
          e.key.toLowerCase() == "ë" ||
          e.key.toLowerCase() == "ê"
        ) {
          this.writtenAnswer += "e";
        } else if (e.key.toLowerCase() == "ñ") {
          this.writtenAnswer += "n";
        } else if (
          e.key.toLowerCase() == "ú" ||
          e.key.toLowerCase() == "û" ||
          e.key.toLowerCase() == "ü"
        ) {
          this.writtenAnswer += "u";
        }
      }
      if (e.key == "Backspace" && this.writtenAnswer.length > 0) {
        this.writtenAnswer = this.writtenAnswer.slice(0, -1);
      }

      if (e.key == "Enter") {
        this.answer();
      }
    },

    chatFocs() {
      this.chatFocus = !this.chatFocus;
    },

    async nextHintTurn() {
      if (!this.isUserPlaying) return;
      else await nextTurn(`${this.$route.params.id}`, this.userProfile.isAdmin);
    },

    async answer() {
      const looseFocusRef = this.$refs.looseFocus;
      looseFocusRef.focus();

      if (this.writtenAnswer.length < 1 || this.answerCooldown) return;

      if (!this.isUserPlaying) {
        let result = {};
        result.code = 113;
        result.message =
          "You are a spectator, wait for next game or join another lobby";
        this.answerResult = result;
        return;
      } else {
        this.answerCooldown = true;
        const theAnswer = this.writtenAnswer;
        this.writtenAnswer = "";
        await answerQuest(
          `${this.$route.params.id}`,
          `${theAnswer}`,
          this.question.rid,
          this.userProfile.id
        );

        setTimeout(() => (this.answerCooldown = false), 400);
      }
    },

    splitAnswerIndex(ind, ind2) {
      let theLength = 0;
      for (let j = 0; j < this.splitAnswer.length; j++) {
        if (ind == j) {
          theLength += ind2;
          return theLength;
        } else {
          theLength += this.splitAnswer[j].length;
        }
      }

      return theLength;
    },

    checkCorretLetters(i) {
      let isCorrectLetter = this.correctLetterObjects.find((e) => {
        return e.index == i;
      });

      return isCorrectLetter ? isCorrectLetter.letter : null;
    },

    windowSize() {
      if (window.innerWidth < 900) {
        this.isLobbyChat = false;
      } else {
        this.isLobbyChat = true;
      }
    },

    closeCardModal() {
      this.modalActiveHint = null;
    },
    async getPrevAnswer(rid) {
      const question = await getQuestion(rid);
      this.$store.commit("UPDATE_PREVIOUS_ANSWER", question);
      return question;
    },
  },

  watch: {
    activeRound: {
      handler() {
        this.answerSplit();
        if (this.activeRound > 1) {
          this.getPrevAnswer(this.game.questions[this.game.round - 2].rid);
        }
        this.writtenAnswer = "";
        this.correctLetterObjects = new Array();
      },
    },

    isGameFinished: {
      handler(newVal, oldVal) {
        if (newVal == true && oldVal == false) {
          this.$store.commit("UPDATE_LEADERBOARD", this.game.users);

          this.$nextTick(() => {
            this.$emit("stopShow");
          });
        }
      },
    },

    hintTurn: {
      handler() {
        if (this.game) {
          if (this.activeRound == 1 && this.hintTurn == 1) {
            this.previousAnswer = {
              answer: this.$t("game.active-game-introduction"),
            };
          }
          this.answerCooldown = true;
          setTimeout(() => (this.answerCooldown = false), 400);
        }
      },
    },

    now: {
      deep: true,
      handler() {
        if (this.roundTimer && this.roundTimer - this.now < 1001) {
          this.nextHintTurn();
        }
        if (
          this.game &&
          Math.round((this.game.roundTimer - this.now) / 1000) < 6 &&
          Math.round((this.game.roundTimer - this.now) / 1000) > 0
        ) {
          this.lessThanFiveSeconds = true;

          if (this.hintTurn == 4) {
            const sound = new Audio(
              require("@/assets/sounds/interval-sound.mp3")
            );
            sound.volume = 0.1;
            sound.play();
          }
        } else {
          this.lessThanFiveSeconds = false;
        }
      },
    },
  },

  components: {
    ActiveGameUsers,
    Keyboard,
    CardCategoryIcon,
    LobbyChat,
    CardModal,
    EyeOutline,
    PreviousAnswer,
    Translate,
  },
};
</script>

<style scoped lang="scss">
.card-list-enter-active,
.card-list-leave-active {
  transition: all 0.5s ease-in;

  transform: translateX(5%);
}
.card-list-enter-from,
.card-list-leave-to {
  transition: all 0.5s cubic-bezier(0.29, 1.01, 1, -0.68);

  transform: rotateY(-180deg) translateX(-50px);
  opacity: 0;
}

.answer-result-transition-enter-active,
.answer-result-transition-leave-active {
  transition: all 0.5s ease-in;

  transform: scale(1.1);
}
.answer-result-transition-enter-from,
.answer-result-transition-leave-to {
  transition: all 0.5s cubic-bezier(0.29, 1.01, 1, -0.68);

  transform: scale(0.5);
  opacity: 0;
}
.active-game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;
  padding-top: 2.5vw;
}

.active-round {
  position: absolute;
  font-size: 1.3rem;
  background: orange;
  padding: 0.4rem 0.8rem;
  border-radius: 1rem;
  top: 1vw;
  box-shadow: 0 0 5px 1px orange;
  z-index: 30;
}
.words-wrapper {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 55vw;
  height: calc(55vw * (2 / 5)); /* 100/56.25 = 1.778 */
  max-height: calc(1025px * (2 / 5));
  max-width: 1025px; /* 16/9 = 1.778 */
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */
  margin: 0.5rem 0;
  align-items: center;
  justify-items: center;
}

.question-hint-image-wrapper {
  display: flex;
  width: calc((55vw / 4) - 6px);
  height: calc(55vw * (2 / 5)); /* 100/56.25 = 1.778 */
  max-height: calc(1025px * (2 / 5));
  max-width: calc((1025px / 4) - 6px); /* 16/9 = 1.778 */
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5%;
  margin: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 1px 4px rgba(0, 0, 0, 0.1) inset,
    -10px -200px 10px 1px rgba(255, 255, 255, 0.1) inset,
    10px 50px 20px 4px rgba(255, 255, 255, 0.1) inset,
    0 0 20px 2px rgba(0, 0, 0, 0.15);
  background: url("~@/assets/images/diamonds.jpg");

  margin: auto 4px;
  border-radius: 8px;
  position: relative;
  background-size: cover;

  user-select: none;
}
.question-hint-image-wrapper:hover {
  transition: 0.5s ease all;
  cursor: pointer;
  transform: rotate3d(15, 1, 1, 15deg) scale(1.4);
  z-index: 40;
}
.question-hint-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  opacity: 1;

  z-index: 3;
  background: rgba(255, 180, 136, 0.39);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 5px 16px 10px rgba(255, 180, 136, 0.39);
  border-radius: 8%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  max-width: 75%;
  max-height: 50%;
  object-fit: contain;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.question-hint-image-container {
  display: flex;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: white;

  background: url("~@/assets/images/golden-background.jpg");

  mix-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@keyframes rotate180y {
  from {
    transform: rotateY(-180deg);
  }
  to {
    transform: rotateY(0);
  }
}

@keyframes rotate180x {
  from {
    transform: rotateX(-180deg);
  }
  to {
    transform: rotateX(0);
  }
}

.question-hint-image-border {
  display: flex;
  position: absolute;
  z-index: 2;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: url("~@/assets/images/golden-borders.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.question-hint-image-category {
  position: absolute;
  bottom: 10%;
}
.answer-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  user-select: none;
  width: 100vw;
}
.answer-sentence-wrapper {
  display: flex;
  margin: 8px 16px;
  flex-wrap: wrap;
}
.answer-sentence-letter {
  display: flex;
  position: relative;
  width: 4rem;
  height: 4rem;
  background: white;
  margin: 0.2rem;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  border: 2px solid rgb(247, 187, 77);
  color: rgb(73, 73, 73);

  * {
    font-size: 2rem;
  }
}

.question-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1vw;
  right: 3.5rem;
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.5rem;
  background: orange;
  border-radius: 50%;
  border: 2px solid whitesmoke;
  font-weight: bold;
  z-index: 30;
}

.question-answer-result {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 30%;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 0px orange;
  box-shadow: 0 0 1rem 1px rgba(0, 0, 0, 0.5);
  background: rgb(241, 241, 241);
  padding: 0.5rem 1rem;
  border-radius: 12px;
  z-index: 99;

  .question-answer-result-points {
    color: rgb(255, 255, 255);
    font-size: 2rem;
  }
}

.code-707 {
  background-color: rgb(255, 100, 100);
}

.code-808 {
  background-color: rgb(80, 245, 80);
}
.code-212 {
  //you already answered
  background-color: rgb(255, 179, 37);
}

.code-113 {
  background: gray;
}

.code-999 {
  //you already knew
  background-color: rgb(255, 72, 0);
}

.correct-letter-wrapper {
  position: absolute;
  z-index: 30;
  right: 0.2rem;
  top: 0.2rem;
  font-size: 0.9rem;
}

.correct-letter-background {
  background-color: rgb(62, 255, 36);
  animation-name: rotate180x;
  animation-duration: 0.5s;
  border: 2px solid rgb(28, 173, 9);
  color: whitesmoke;
}

.active-game-chat {
  position: absolute;
  bottom: 1vw;
  left: 1vw;
  display: flex;
  width: 300px;
}

.active-game-spectating {
  position: absolute;
  bottom: 1%;
  user-select: none;
  display: flex;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.3);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
}

.question-language {
  display: flex;
  width: 55vw;
  height: 10px;
  max-width: 1025px; /* 16/9 = 1.778 */
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */

  align-items: center;
  justify-items: center;
  height: 15px;
  padding-left: 1rem;
  div {
    font-size: 12px;
    font-weight: bold;
    color: whitesmoke;
  }
}
@media only screen and (max-width: 1100px) {
  .questions-inputs {
    width: 100vw;
    height: 100%;
    max-height: 70vh;
    max-width: calc(70vh * (16 / 9)); /* 16/9 = 1.778 */

    top: 0;
    bottom: 0; /* vertical center */
    left: 0;
    right: 0; /* horizontal center */
  }

  .words-wrapper {
    grid-template-columns: 25% 25% 25% 25%;
    // width: 55vw;
    // height: calc(55vw * (2 / 5)); /* 100/56.25 = 1.778 */
    // max-height: calc(1025px * (2 / 5));
    // max-width: 1025px; /* 16/9 = 1.778 */
    // top: 0;
    // bottom: 0; /* vertical center */
    // left: 0;
    // right: 0; /* horizontal center */
    // margin: 12px 0;
    // align-items: center;
    // justify-items: center;
    width: 100vw;
    height: calc(100vw * (2 / 5)); /* 100/56.25 = 1.778 */
    max-height: 70vh;
    max-width: calc(70vh * (5 / 2)); /* 16/9 = 1.778 */

    top: 0;
    bottom: 0; /* vertical center */
    left: 0;
    right: 0; /* horizontal center */
  }

  .question-hint-image-wrapper {
    width: calc(25vw - 4px);
    height: calc(100vw * (2 / 5)); /* 100/56.25 = 1.778 */
    max-height: 70vh;
    max-width: calc((70vh * (5 / 2) / 4) - 4px); /* 16/9 = 1.778 */
  }

  .question-language {
    div {
      font-size: 10px;
    }
  }
}
</style>
