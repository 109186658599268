import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/css/main.scss";
import titleMixin from "./mixins/titleMixin";
import i18n from "./i18n";
import VueSocialSharing from 'vue-social-sharing'


createApp(App).mixin(titleMixin).use(store).use(i18n).use(router).use(VueSocialSharing).mount("#app");
