<template>
  <div class="invite" @click.self="isInviteModal = false">
    <div class="invite-wrapper shadow-1">
      <div class="invite-close" @click="isInviteModal = false">
        <CloseThick></CloseThick>
      </div>
      <div
        class="copied-message shadow-1 slideFromLeftAnimation"
        v-if="copiedMessage"
      >
        {{ copiedMessage }}
      </div>
      <div class="invite-link-wrapper slideFromLeftAnimation">
        <input
          type="text"
          class="invite-link-input"
          v-model="inviteLink"
          readonly
          @click="copyLink()"
        />

        <button class="invite-link-icon" @click="copyLink()">
          <ContentCopy class="flex justify-center align-center"></ContentCopy>
        </button>
      </div>

      <div class="invite-social-medias-wrapper slideFromLeftAnimation">
        <ShareNetwork
          class="invite-link-icon"
          network="facebook"
          :url="'https://okuiz.com' + $route.fullPath"
          title="You are invited to play Okuiz"
          description="Quiz show that you can play with friends. Make your guess based on given images."
          quote="It's OK to not know, but it's not OK to not try. -Wise Man"
          hashtags="playokuiz,quiz show,game"
        >
          <Facebook class="flex justify-center align-center"></Facebook>
        </ShareNetwork>

        <ShareNetwork
          class="invite-link-icon"
          network="twitter"
          :url="'https://okuiz.com' + $route.fullPath"
          :title="$t('invite.you-are-invited')"
          :description="$t('invite.description')"
          quote="It's OK to not know, but it's not OK to not try. -Wise Man"
          hashtags="playokuiz,quizshow,game"
        >
          <Twitter class="flex justify-center align-center"></Twitter>
        </ShareNetwork>
        <ShareNetwork
          class="invite-link-icon"
          network="reddit"
          :url="'https://okuiz.com' + $route.fullPath"
          :title="$t('invite.you-are-invited')"
          :description="$t('invite.description')"
          quote="It's OK to not know, but it's not OK to not try. -Wise Man"
          hashtags="playokuiz,quizshow,game"
        >
          <Reddit class="flex justify-center align-center"></Reddit>
        </ShareNetwork>

    
             <ShareNetwork
          class="invite-link-icon"
          network="whatsapp"
          :url="'https://okuiz.com' + $route.fullPath"
          :title="$t('invite.you-are-invited')"
          :description="$t('invite.description')"
          quote="It's OK to not know, but it's not OK to not try. -Wise Man"
          hashtags="playokuiz,quizshow,game"
        >
          <Whatsapp class="flex justify-center align-center"></Whatsapp>
        </ShareNetwork>
     
             <ShareNetwork
          class="invite-link-icon"
          network="telegram"
          :url="'https://okuiz.com' + $route.fullPath"
          :title="$t('invite.you-are-invited')"
          :description="$t('invite.description')"
          quote="It's OK to not know, but it's not OK to not try. -Wise Man"
          hashtags="playokuiz,quizshow,game"
        >
          <Send class="flex justify-center align-center"></Send>
        </ShareNetwork>
             <ShareNetwork
          class="invite-link-icon"
          network="linkedin"
          :url="'https://okuiz.com' + $route.fullPath"
          :title="$t('invite.you-are-invited')"
          :description="$t('invite.description')"
          quote="It's OK to not know, but it's not OK to not try. -Wise Man"
          hashtags="playokuiz,quizshow,game"
        >
          <Linkedin class="flex justify-center align-center"></Linkedin>
        </ShareNetwork>
      </div>
    </div>
  </div>
</template>

<script>
import ContentCopy from "vue-material-design-icons/ContentCopy.vue";
import Facebook from "vue-material-design-icons/Facebook.vue";
import Twitter from "vue-material-design-icons/Twitter.vue";
import Reddit from "vue-material-design-icons/Reddit.vue";
import Send from "vue-material-design-icons/Send.vue";

import Linkedin from "vue-material-design-icons/Linkedin.vue";

import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import CloseThick from "vue-material-design-icons/CloseThick.vue";

export default {
  data() {
    return {
      copiedMessage: "",
    };
  },
  computed: {
    inviteLink() {
      return window.location.host + this.$route.fullPath;
    },

    isInviteModal: {
      get() {
        return this.$store.state.isInviteModal;
      },
      set(value) {
        this.$store.commit("UPDATE_IS_INVITE_MODAL", value);
      },
    },
  },

  components: {
    ContentCopy,
    Facebook,
    Twitter,
    Reddit,Send,Linkedin,
    Whatsapp,
    CloseThick,
  },
  methods: {
    copyLink() {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      const message = this.inviteLink;
      clipboardData.writeText(message);

      this.copiedMessage = "Copied!!";

      setTimeout(() => (this.copiedMessage = ""), 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.invite {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.2);
}

.invite-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 200px;
  overflow: hidden;

  width: 400px;
  max-width: 99%;
  padding: 24px;
  padding-top: 40px;
  border-radius: 12px;

  background: rgb(255, 242, 230);
}

.invite-close {
  position: absolute;
  right: 8px;
  top: 8px;
  color: rgb(97, 84, 59);
  cursor: pointer;
}
.invite-close:hover {
  opacity: 0.9;
}

.copied-message {
  position: absolute;
  width: 90px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 38%;
  left: 38%;
  background: rgb(42, 221, 42);
  border-radius: 12px;
  color: whitesmoke;
}
.invite-link-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
.invite-link-input {
  display: flex;
  width: 80%;
  padding: 8px 12px;
  border-radius: 12px;
  border: 2px rgba(0, 0, 0, 0.2) solid;
  cursor: pointer;
  font-size: 16px;
}

.invite-link-icon {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  background: orange;
  color: white;
  border: 2px rgba(0, 0, 0, 0.2) solid;

  border-radius: 50%;
}

.invite-social-medias-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: auto;
  * {
    margin: 8px;
  }
}
</style>
