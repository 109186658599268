<template>
  <div
    :class="sizeProp > 24 ? 'card-category-modal-sizes' : ''"
    class="card-category-icon-wrapper"
  >
    <div class="card-category-icon-popover">{{ $t(`categories.${category.toLowerCase()}`) }}</div>
    <Soccer
      class="card-category-icon"
      :size="sizeProp"
      v-if="category == 'Football'"
    ></Soccer>
    <Basketball
      class="card-category-icon"
      :size="sizeProp"
      v-if="category == 'Basketball'"
    ></Basketball>
    <MapMarker
      class="card-category-icon"
      :size="sizeProp"
      v-if="category == 'Countries'"
    ></MapMarker>
    <FootPrint
      class="card-category-icon"
      :size="sizeProp"
      v-if="category == 'Historical Characters'"
    ></FootPrint>
    <MovieOpen
      class="card-category-icon"
      :size="sizeProp"
      v-if="category == 'Movies'"
    />
    <Television
      class="card-category-icon"
      :size="sizeProp"
      v-if="category == 'Tv series'"
    ></Television>
  <DramaMasks
      class="card-category-icon"
      :size="sizeProp"
      v-if="category == 'Actors'"
    ></DramaMasks>
    <City
      class="card-category-icon"
      :size="sizeProp"
      v-if="category == 'Cities'"
    ></City>
    <ControllerClassic
      class="card-category-icon"
      :size="sizeProp"
      v-if="category == 'Games'"
    ></ControllerClassic>
  </div>
</template>

<script>
import Soccer from "vue-material-design-icons/Soccer.vue";
import MapMarker from "vue-material-design-icons/MapMarker.vue";
import Basketball from "vue-material-design-icons/Basketball.vue";
import FootPrint from "vue-material-design-icons/FootPrint.vue";
import MovieOpen from "vue-material-design-icons/MovieOpen.vue";
import DramaMasks from "vue-material-design-icons/DramaMasks.vue";
import Television from "vue-material-design-icons/Television.vue";

import City from "vue-material-design-icons/City.vue";
import ControllerClassic from "vue-material-design-icons/ControllerClassic.vue";

export default {
  props: {
    category: String,
    sizeProp: {
      type: Number,
      default: 24,
    },
  },

  components: {
    Soccer,
    MapMarker,
    Basketball,
    FootPrint,
    MovieOpen,
    Television,
    DramaMasks,
    City,
    ControllerClassic,
  },
};
</script>

<style scoped lang="scss">
.card-category-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  padding: 2px;
  background: #b39246;
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.2) inset,
    0px 0px 10px 1px rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  border: 2px solid rgb(255, 238, 0);
  color: #fdf9c4;
}

.card-category-icon-wrapper {
  position: relative;
  z-index: 40;
}

.card-category-icon-wrapper:hover {
  .card-category-icon-popover {
    display: flex;
  }
}
.card-category-icon-popover {
  display: none;
  position: absolute;
  top: -1rem;
  background: rgb(60, 60, 90);
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
}

.card-category-modal-sizes {
  width: 7vh !important;
  height: 7vh !important;
  .card-category-icon {
    width: 7vh !important;
    height: 7vh !important;
  }
}

@media only screen and (max-width: 700px) {
  .card-category-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 1px;
    box-shadow: none;
  }
}
</style>
