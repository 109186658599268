<template>
  <div @keyup.enter="search" class="kuizbook-search-wrapper">
    <input
      class="kuizbook-search-input"
      :placeholder="$t('kuizbook.search-placeholder')"
      type="text"
      v-model="kuizbookSearch"
    />
    <button class="kuizbook-search-button" @click="search">
      {{ $t("lobbies.search") }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      kuizbookSearch: "",
    };
  },

  methods: {
    search() {
      this.$router.push({
        path: "/kuizbook",
        query: { search: this.kuizbookSearch }
      });
this.kuizbookSearch = ''

    },
  },
};
</script>

<style lang="scss" scoped>
.kuizbook-search-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.kuizbook-search-input {
  display: flex;
  width: 400px;
  height: 36px;
  border-radius: 12px;
  border: none;
  outline: none;
  padding-left: 10px;
  font-size: 16px;
}
.kuizbook-search-button {
  display: flex;
  width: 100px;
  height: 36px;
  background: skyblue;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  font-size: 16px;
}
</style>
