<template>
  <div class="card-modal" @click.self="closeModal">
    <div class="question-hint-close-modal" @click.self="closeModal">X</div>
    <div name="card-list" >
      <div class="question-hint-image-wrapper">
        <div class="question-hint-image-border"></div>
        <img class="question-hint-image" :src="activeHint.url" />

        <div class="question-hint-image-container"></div>

        <CardCategoryIcon
          :category="questionCategory"
          class="question-hint-image-category"
          :sizeProp="50"
        ></CardCategoryIcon>
      </div>
    </div>
  </div>
</template>

<script>
import CardCategoryIcon from './CardCategoryIcon.vue'


export default {
  props: {
    activeHint: Object,
    questionCategory: String,
  },

  components:{
    CardCategoryIcon
  },

  methods:{
    closeModal(){
      this.$emit('isCardModal')
    }
  }


};
</script>

<style scoped lang="scss">
.card-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
  justify-content: center;
  align-items: center;
}

.words-wrapper {
  display: flex;
  justify-content: center;
}

.question-hint-image-wrapper {
  display: flex;
  width: calc((70vh));
  height: calc(100vh ); /* 100/56.25 = 1.778 */
max-width: 100vw;
max-height: calc(180vw);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 5%;
  margin: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.1) inset,
    0 0 1px 4px rgba(0, 0, 0, 0.1) inset,
    -10px -200px 10px 1px rgba(255, 255, 255, 0.1) inset,
    10px 50px 20px 4px rgba(255, 255, 255, 0.1) inset,
    0 0 20px 2px rgba(0, 0, 0, 0.15);
  background: url("~@/assets/images/diamonds.jpg");

  margin: auto 4px;
  border-radius: 8px;
  position: relative;
  background-size: cover;

  user-select: none;
}

.question-hint-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  opacity: 1;
  z-index: 3;
  background: rgba(255, 180, 136, 0.39);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 5px 16px 10px rgba(255, 180, 136, 0.39);
  border-radius: 8%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 60%;
  max-width: 75%;
  max-height: 75%;
  object-fit: contain;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.question-hint-image-border {
  display: flex;
  position: absolute;
  z-index: 2;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: url("~@/assets/images/golden-borders.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.question-hint-image-container {
  display: flex;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: white;

  background: url("~@/assets/images/golden-background.jpg");

  mix-blend-mode: overlay;
  background-repeat: no-repeat;
  background-size: 100% 100%;


}

.question-hint-image-category {
  position: absolute;
  bottom: 10%;

}


.question-hint-close-modal{
  position: absolute;
  top: 2vw;
  right: 2vw;
width: 4rem;
height: 4rem;
display:flex;
justify-content: center;
align-items: center;
border-radius: 50%;
border: 2px solid white;
background: orange;
z-index: 105;
cursor: pointer;
font-size: 2rem;
}
</style>
