<template>
  <div id="keyboard-cont">
    <div class="first-row">
      <button class="keyboard-button" @click="clickedLetter(`'`)">'</button>

      <button class="keyboard-button" @click="clickedLetter('1')">1</button>
      <button class="keyboard-button" @click="clickedLetter('2')">2</button>
      <button class="keyboard-button" @click="clickedLetter('3')">3</button>
      <button class="keyboard-button" @click="clickedLetter('4')">4</button>
      <button class="keyboard-button" @click="clickedLetter('5')">5</button>
      <button class="keyboard-button" @click="clickedLetter('6')">6</button>
      <button class="keyboard-button" @click="clickedLetter('7')">7</button>
      <button class="keyboard-button" @click="clickedLetter('8')">8</button>
      <button class="keyboard-button" @click="clickedLetter('9')">9</button>
      <button class="keyboard-button" @click="clickedLetter('0')">0</button>
    </div>
    <div class="second-row">
      <button class="keyboard-button" @click="clickedLetter('q')">q</button>
      <button class="keyboard-button" @click="clickedLetter('w')">w</button>
      <button class="keyboard-button" @click="clickedLetter('e')">e</button>
      <button class="keyboard-button" @click="clickedLetter('r')">r</button>
      <button class="keyboard-button" @click="clickedLetter('t')">t</button>
      <button class="keyboard-button" @click="clickedLetter('y')">y</button>
      <button class="keyboard-button" @click="clickedLetter('u')">u</button>
      <button class="keyboard-button" @click="clickedLetter('i')">i</button>
      <button class="keyboard-button" @click="clickedLetter('o')">o</button>
      <button class="keyboard-button" @click="clickedLetter('p')">p</button>
    </div>
    <div class="third-row">
      <button class="keyboard-button" @click="clickedLetter('a')">a</button>
      <button class="keyboard-button" @click="clickedLetter('s')">s</button>
      <button class="keyboard-button" @click="clickedLetter('d')">d</button>
      <button class="keyboard-button" @click="clickedLetter('f')">f</button>
      <button class="keyboard-button" @click="clickedLetter('g')">g</button>
      <button class="keyboard-button" @click="clickedLetter('h')">h</button>
      <button class="keyboard-button" @click="clickedLetter('j')">j</button>
      <button class="keyboard-button" @click="clickedLetter('k')">k</button>
      <button class="keyboard-button" @click="clickedLetter('l')">l</button>
    </div>
    <div class="fourth-row">
      <button class="keyboard-button" @click="clickedLetter('z')">z</button>
      <button class="keyboard-button" @click="clickedLetter('x')">x</button>
      <button class="keyboard-button" @click="clickedLetter('c')">c</button>
      <button class="keyboard-button" @click="clickedLetter('v')">v</button>
      <button class="keyboard-button" @click="clickedLetter('b')">b</button>
      <button class="keyboard-button" @click="clickedLetter('n')">n</button>
      <button class="keyboard-button" @click="clickedLetter('m')">m</button>
      <button class="keyboard-button keyboard-button-backspace" @click="clickedLetter('Backspace')">
        <span>DEL</span> ⬅
      </button>
    </div>

    <div class="last-row">
      <button @click="answer" class="keyboard-send">OK</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    answer() {
      this.$emit("answerQuestion");
    },

    clickedLetter(l) {
      let e = {};
      e.key = l;
      this.$emit("clickedLetter", e);
    },
  },
};
</script>

<style lang="scss" scoped>
#keyboard-cont {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: whitesmoke;
  padding: 1rem;
  border-radius: 8px;
  border: 2px solid rgb(148, 148, 148);
  box-shadow: -1px -1px 0 1px rgb(185, 185, 185) inset,
    1px 1px 15px 1px rgba(0, 0, 0, 0.1);
}

#keyboard-cont div {
  display: flex;
}

.keyboard-button {
  display: flex;
  width: 3.6rem;
  height: 3.6rem;
  background: white;
  margin: 0.1rem;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  border: 0.2rem solid rgb(148, 148, 148);
  box-shadow: -1px -1px 0 1px rgb(185, 185, 185) inset,
    1px 1px 15px 1px rgba(0, 0, 0, 0.07);
  color: rgb(97, 97, 97);
}


.first-row {
  button {
    width: 3.2rem;
    height: 3.3rem;
  }
}

.last-row {
  display: flex;
  width: 50%;
}
.keyboard-send {
  display: flex;
  width: 100%;
  height: 3.2rem;
  background: rgb(255, 183, 50);
  margin: 0.1rem;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  border: 2px solid rgb(226, 161, 40);
  color: rgb(247, 247, 247);

  box-shadow: -1px -1px 0 1px rgb(214, 153, 39) inset,
    1px 1px 15px 1px rgba(0, 0, 0, 0.1);
}

.keyboard-button-backspace{
 display: flex;
 flex-direction: column;
}
.keyboard-button:focus{
  border: 0.2rem solid rgb(185, 185, 185) ;
}
@media only screen and (max-width: 700px) {
  .keyboard-button {
    width: 3.9rem;
    height: 5rem;
    border: 1px solid rgb(185, 185, 185) ;

    border-right: 3px solid rgb(185, 185, 185) ;
    border-bottom: 3px solid rgb(185, 185, 185) ;

    box-shadow: none;
  }

.keyboard-button-backspace{
  width: 6rem;
}
  .first-row {
    button {
      width: 3.4rem;
      height: 5rem;
    }
  }

  .keyboard-send {
  display: flex;
  width: 100%;
  height: 5rem;
 
}


}
</style>
