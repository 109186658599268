import { createStore } from "vuex";

export default createStore({
  state: {
    lobby: "",
    lobbyId: "",
    user: JSON.parse(localStorage.getItem("user")),
    invitePath: "",
    chatSound: JSON.parse(localStorage.getItem("chatSound")),
    kickedFromLobbyMessage: "",
    pageLoading: false,
    isInviteModal: false,
    leaderboard: null,
    correctLetterObjects: [],
    answerResult:null,
    previousAnswer:null,
    kuizInfo:null,
  },
  mutations: {
    UPDATE_USER(state, value) {
      localStorage.setItem("user", JSON.stringify(value));

      state.user = value;
    },

    UPDATE_INVITE_PATH(state, value) {
      state.invitePath = value;
    },

    UPDATE_CHAT_SOUND(state, value) {
      localStorage.setItem("chatSound", JSON.stringify(value));

      state.chatSound = value;
    },

    UPDATE_LOBBY_KICKED_MESSAGE(state, value) {
      state.kickedFromLobbyMessage = value;
    },

    UPDATE_PAGE_LOADING(state, value) {
      state.pageLoading = value;
    },
    UPDATE_IS_INVITE_MODAL(state, value) {
      state.isInviteModal = value;
    },

    UPDATE_LEADERBOARD(state, value) {
      state.leaderboard = value;
    },

    PUSH_CORRECT_LETTER_OBJECT(state, value) {
      let alreadyThere = state.correctLetterObjects.find((e) => {
        return e.letter == value.letter && e.index == value.index;
      });

      if (!alreadyThere) {
        state.correctLetterObjects.push(value);
      }
    },

    CLEAR_LETTER_OBJECT(state, value) {
      state.correctLetterObjects = value;
    },
    UPDATE_ANSWER_RESULT(state, value) {
      state.answerResult = value;
      setTimeout(() => state.answerResult = null , 1500)
    },

    UPDATE_PREVIOUS_ANSWER(state, value) {
      state.previousAnswer = null;
      state.previousAnswer = value;
      setTimeout(() => state.previousAnswer = null , 3000)

    },

    UPDATE_KUIZ_INFO(state, value) {
      state.kuizInfo = value;
    },
  },
  actions: {
    updateUser({ commit }, value) {
      commit("UPDATE_USER", value);
    },

    updateInvitePath({ commit }, value) {
      commit("UPDATE_INVITE_PATH", value);
    },
    updateChatSound({ commit }, value) {
      commit("UPDATE_CHAT_SOUND", value);
    },

    updateKickedFromLobbyMessage({ commit }, value) {
      commit("UPDATE_LOBBY_KICKED_MESSAGE", value);
    },

    updatePageLoading({ commit }, value) {
      commit("UPDATE_PAGE_LOADING", value);
    },
    updateIsInviteModal({ commit }, value) {
      commit("UPDATE_IS_INVITE_MODAL", value);
    },

    updateLeaderboard({ commit }, value) {
      commit("UPDATE_LEADERBOARD", value);
    },

    updateCorrectLetters({ commit }, value) {
      commit("PUSH_CORRECT_LETTER_OBJECT", value);
      commit("CLEAR_LETTER_OBJECT", value);
    },

    updateAnswerResult({ commit }, value) {
      commit("UPDATE_ANSWER_RESULT", value);
    },

    updatePreviousAnswer({ commit }, value) {
      commit("UPDATE_PREVIOUS_ANSWER", value);
    },
  },
  modules: {},
});
