<template>
  <div
    class="active-game-users slideFromLeftAnimation"
    :style="!isLeaderboard ? 'width:fit-content;' : ''"
    v-if="users"
  >
    <div class="active-game-users-title">
      <div class="flex justify-center align-center">
        {{ $t("game.leaderboard") }}
      </div>
      <button
        class="active-game-users-close-button"
        @click="isLeaderboard = !isLeaderboard"
      >
        <Menu :size="14" class="flex justify-center align-center"></Menu>
      </button>
    </div>
    <TransitionGroup name="list" tag="list" class="slideFromLeftAnimation">
      <ul
        class="active-game-user"
        v-for="user in usersSortByPoints"
        :key="user.id"
        v-show="isLeaderboard"
      >
        <div class="flex">
          {{ user.userName }}
          <div class="ml-1" v-if="user.id == userid">
            ({{ $t("game.you") }})
          </div>
        </div>
        <div class="ml-auto"><AnimateNumber :value="user.points" /></div>
      </ul>
    </TransitionGroup>
  </div>
</template>

<script>
import Menu from "vue-material-design-icons/Menu.vue";
import AnimateNumber from "./AnimateNumber";

export default {
  props: {
    users: Array,
    userid: String,
  },
  data() {
    return {
      isLeaderboard: true,
    };
  },
  computed: {
    usersSortByPoints: function () {
      let result = [...this.users];
      result = result.sort((a, b) => {
        return b.points - a.points;
      });

      return result;
    },
  },

  components: {
    Menu,
    AnimateNumber,
  },
};
</script>

<style lang="scss" scoped>
.active-game-users {
  position: absolute;
  top: 0.8vw;
  left: 3%;
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  max-width: 17rem;
  max-height: 15rem;
  font-size: 24px;
  user-select: none;
  z-index: 90;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
  opacity: 0.6;
  transition: 1s;
}

.active-game-users:hover {
  opacity: 1;
}

.active-game-user {
  display: flex;
  padding: 0.2rem 1.2rem;
}
.active-game-users-title {
  display: flex;
  justify-content: space-between;
  background: orange;
  padding: 0.3rem 0.8rem;
  * {
    margin: 0 2px;
  }
}

.active-game-users-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(230, 150, 2);
  color: white;
  border-radius: 50%;
  padding: 0.3rem;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
