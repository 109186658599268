<template>
  <div class="users-wrapper slideFromLeftAnimation">
    <div class="users-info-tab">
      <div class="flex justify-center align-items users-lobby-name">
        <Home class="flex justify-center align-center mr-2"></Home>
        <div class="flex justify-center align-center">{{ lobbyName }}</div>
      </div>
      <div class="flex justify-center align-center">
        {{ users.length }} / {{ userLimit }}
      </div>
    </div>
    
    <div class="users-tab-wrapper" id="users-tab-wrapper">
      <TransitionGroup name="list" tag="div">
        <div
          class="user-tab"
          v-for="(user) in usersSortByTime"
          :key="user.id"
        >
          <div
            class="user-role"
            :class="[user == userProfile ? 'user-role-bg' : '']"
          >
            <Account></Account>
          </div>

          <div class="user-name">
            {{ user.userName }}
          </div>

          <div class="user-ak">
            <button
              v-if="userProfile.isAdmin && !user.isAdmin"
              @click="kickUserFromLobby(user)"
            >
              <Karate class="user-kick"></Karate>
            </button>
          </div>

          <div class="user-checkbox">
            <CheckboxBlankOutline
              class="user-not-ready"
              v-if="!user.isReady"
            ></CheckboxBlankOutline>
            <CheckboxOutline
              class="user-ready"
              v-if="user.isReady && !user.isAdmin"
            ></CheckboxOutline>
            <Crown
              class="user-role user-role-admin"
              v-if="user.isAdmin"
            ></Crown>
          </div>
        </div>
      </TransitionGroup>
    </div>
        <div class="flex justify-center align-center mt-auto cl-warning" v-if="startingError">{{startingError}}</div>

    <div class="users-bottom-tab">
      
      <button
        @click="userIsReadyCheckbox"
        v-if="!userProfile.isAdmin"
        :class="userProfile.isReady ? 'user-is-ready-button' : ''"
      >
        {{ userProfile.isReady ?  $t("game.not-ready"): $t("game.ready")  }}
      </button>

      <button v-if="userProfile.isAdmin" @click="startGame">
        {{ $t("game.start") }}
      </button>
    </div>
  </div>
</template>

<script>
import Account from "vue-material-design-icons/Account.vue";
import Crown from "vue-material-design-icons/Crown.vue";
import Home from "vue-material-design-icons/Home.vue";
import CheckboxOutline from "vue-material-design-icons/CheckboxOutline.vue";
import CheckboxBlankOutline from "vue-material-design-icons/CheckboxBlankOutline.vue";
import Karate from "vue-material-design-icons/Karate.vue";

import { userIsReady, kickUser, lobbyStartGame } from "../firebase/db";

export default {
  data() {
    return {
      startingError:''
    }
  },
  props: {
    users: Array,
    userLimit: Number,
    userProfile: Object,
    lobbyName: String,
    lobbyId: String,
    questionSize:Number,
  },
  components: {
    Account,
    Crown,
    Home,
    CheckboxOutline,
    CheckboxBlankOutline,
    Karate,
  },
  computed: {
    usersSortByTime: function () {
      let users = this.users;
      return users.sort((a, b) => {
        return a.joinedAt - b.joinedAt;
      });
    },

    pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    },
  },

  methods: {
    userIsReadyCheckbox() {
      userIsReady(this.lobbyId, this.userProfile);
    },

    kickUserFromLobby(user) {
      kickUser(`${this.$route.params.id}`, user);
    },

    async startGame() {
      this.pageLoading = true;
      let notReady = this.users.find((user) => {
        return !user.isReady;
      });
      if (notReady) {
        this.startingError = `${this.$t('game.everyone-should-ready')}`;
        setTimeout(() => (this.startingError = ""), 2000);
      } else {
        await lobbyStartGame(this.lobbyId,this.questionSize);
      }

      this.pageLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.users-wrapper {
  display: flex;
  flex-direction: column;
  width: 360px;
  max-width: 99%;
  background: rgb(240, 240, 240);

  border-radius: 12px;
  width: 100%;
  max-width: 100%;
  height: 90%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
  margin: auto 0;
  min-height: 250px !important;
}
.users-info-tab {
  display: flex;
  padding: 16px 24px;
  font-weight: bold;
  background: skyblue;
  justify-content: space-around;
}
.users-tab-wrapper {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
}
.user-tab {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 2fr;
  padding: 12px 5%;
  color: black;
}

.user-role {
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgb(111, 177, 240);
  justify-content: center;
  align-items: center;
  color: white;
  box-shadow: 0px 0px 6px 1px rgba(48, 48, 48, 0.2);
}

.user-role-bg {
  background: rgb(241, 209, 62) !important;
}

.user-role-admin {
  background: rgb(255, 98, 98);
}
.user-name {
  display: flex;
  padding-left: 5%;
  align-items: center;
  font-weight: bold;
  color: rgb(60, 60, 60);
}

.user-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.users-bottom-tab {
  display: flex;
  padding: 16px 24px;
  font-weight: bold;
  background: skyblue;
  margin-top: auto;
  justify-content: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(42, 221, 42);
    color: white;
    height: 2rem;
    min-height: 30px;
    width: 96px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 12px;
  }

  .user-is-ready-button {
    background-color: rgb(241, 209, 62);
  }
}
.user-ak {
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-kick {
  color: rgb(255, 98, 98);
}
.user-not-ready {
  color: rgb(241, 209, 62);
}
.user-ready {
  color: rgb(42, 221, 42);
}

#users-tab-wrapper::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

#users-tab-wrapper::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#users-tab-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: skyblue;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

@media only screen and (max-width: 900px) {
  .user-role {
    display: none;
  }
  .users-info-tab {
    display: grid;
    grid-template-columns: 100%;
    padding: 8px;
    font-weight: bold;
    background: skyblue;
  }

  .users-lobby-name {
    display: none;
  }

  .user-tab {
    grid-template-columns: 60% 15% 25%;
    padding: 3px;
    * {
      font-size: 12px;
    }
  }

  .users-bottom-tab {
    padding: 4px;
  }
  .user-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
