<template>
  <div class="not-found">
     <img class="not-found-image" :src="svg" />
     <div class="not-found-index">
    <h1 class="cl-light">{{ $t("404.not-found") }}</h1>
    <router-link to="/" class="btn">{{ $t("404.go-to-home") }}</router-link>
     </div>

  </div>
</template>

<script>
export default{
data() {
    return {
        svg: ''
    };
},
title () {
    return `404 Not Found | okuiz `
  },

created(){
    this.svg = require(`@/assets/images/landscape-illusturations/DrawKit Vector Illustration Landscape & Scenery (${Math.floor(Math.random() * 15  + 1)}).svg`);
}
}

</script>

<style scoped lang="scss">
.not-found {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.not-found-index{
  position: absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  *{
    margin: 1rem;
    
  }

  h1{
    font-size: 2rem;
    text-shadow: 1px 1px orange;
  }
}
.not-found-image{
  width: 100vh;
  height: 100vh;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}
</style>
