<template>
  <div class="page-loading">
      <div class="page-wrapper">
    <div class="loadingio-spinner-bean-eater-fjndyxu0d1a"><div class="ldio-z7q7t4gqcz">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>
  </div>

 

</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.page-loading{
    display:flex;
  flex-direction: column;
  width: 100%;
height: 100vh;
position: fixed;
justify-content: center;
align-items: center;
text-align: center;
top: 0;
left: 0;
background: rgba(0, 0, 0, 0.5);
font-size: 36px;
font-weight: bold;
color: white;
z-index: 99;
}

.page-wrapper{
    position: absolute;
    animation: turn360 4s ease-in infinite;
   

}

@keyframes turn360 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-360deg) }
  100% { transform: rotate(0deg) }
}
@keyframes ldio-z7q7t4gqcz-1 {
    0% { transform: rotate(0deg) }
   50% { transform: rotate(-45deg) }
  100% { transform: rotate(0deg) }
}
@keyframes ldio-z7q7t4gqcz-2 {
    0% { transform: rotate(180deg) }
   50% { transform: rotate(225deg) }
  100% { transform: rotate(180deg) }
}
.ldio-z7q7t4gqcz > div:nth-child(2) {
  transform: translate(-15px,0);
}
.ldio-z7q7t4gqcz > div:nth-child(2) div {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 120px;
  height: 60px;
  border-radius: 120px 120px 0 0;
  background: #93dbe9;
  animation: ldio-z7q7t4gqcz-1 1s linear infinite;
  transform-origin: 60px 60px
}
.ldio-z7q7t4gqcz > div:nth-child(2) div:nth-child(2) {
  animation: ldio-z7q7t4gqcz-2 1s linear infinite
}
.ldio-z7q7t4gqcz > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}@keyframes ldio-z7q7t4gqcz-3 {
    0% { transform: translate(190px,0); opacity: 0 }
   20% { opacity: 1 }
  100% { transform: translate(70px,0); opacity: 1 }
}
.ldio-z7q7t4gqcz > div:nth-child(1) {
  display: block;
}
.ldio-z7q7t4gqcz > div:nth-child(1) div {
  position: absolute;
  top: 92px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #689cc5;
  animation: ldio-z7q7t4gqcz-3 1s linear infinite
}
.ldio-z7q7t4gqcz > div:nth-child(1) div:nth-child(1) { animation-delay: -0.67s }
.ldio-z7q7t4gqcz > div:nth-child(1) div:nth-child(2) { animation-delay: -0.33s }
.ldio-z7q7t4gqcz > div:nth-child(1) div:nth-child(3) { animation-delay: 0s }
.loadingio-spinner-bean-eater-fjndyxu0d1a {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-z7q7t4gqcz {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-z7q7t4gqcz div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>

<style type="text/css">
</style>