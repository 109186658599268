<template>
  <div class="footer">
      <router-link to="/privacy">{{$t('home.privacy')}}</router-link>
      <router-link to="/cookies">{{$t('home.cookies')}}</router-link>

      <router-link to="/contact">{{$t('home.contact')}}</router-link>

  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">

.footer{
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  *{
    margin: 0 8px;
    font-size: 14px;
  color: rgba(255, 210, 8, 0.5);

  }
}
</style>