<template>
  <div class="lobby-chat slideFromLeftAnimation" v-show="isLobbyChat">
    <div class="lobby-chat-title">
      <div class="flex align-center">
        <ChatIcon class="mr-2"></ChatIcon> {{ $t("game.chat") }}
      </div>
      <button class="ml-auto mr-3" @click="chatSound = !chatSound">
        <BellOff
          :size="20"
          class="flex align-center cl-light"
          v-if="!chatSound"
        ></BellOff>

        <Bell :size="20" class="flex align-center cl-light" v-else></Bell>
      </button>

      <button @click="isLobbyChat = !isLobbyChat">
        <Minus class="flex align-center cl-light"></Minus>
      </button>
    </div>
    <div
      class="lobby-chat-messages"
      id="chat-messages"
      ref="messages-container"
    >
      <TransitionGroup name="list" tag="div">
        <div
          class="lobby-chat-wrapper"
          v-for="(message, index) in chat"
          :key="index"
        >
          <div class="lobby-chat-message">
            <div class="lobby-chat-message-user">
              <span>{{ message.user.userName }}</span>
              <span class="lobby-chat-message-time">{{
                timestampToDate(message.createdAt)
              }}</span>
            </div>

            <div class="lobby-chat-message-index">{{ message.message }}</div>
          </div>
        </div>
      </TransitionGroup>
    </div>
    <div class="lobby-chat-message-sender" @keyup.enter="sendMessage">
      <input
        @focus="chatFo"
        @blur="chatFo"
        class="lobby-chat-message-sender-input"
        :placeholder="`${$t('game.enter-message')}`"
        v-model="inputMessage"
      />
      <button
        v-if="!isSpaming"
        class="lobby-chat-message-sender-button"
        @click="sendMessage"
      >
        <PanUp></PanUp>
      </button>
      <div
        v-if="isSpaming"
        class="lobby-chat-message-sender-button"
        @click="sendMessage"
      >
        {{ spamCount }}
      </div>
    </div>
  </div>
  <span v-show="!isLobbyChat"></span>
  <button
    @click="openLobbyChat"
    v-show="!isLobbyChat"
    class="lobby-chat-visibility-button"
  >
    <ChatIcon class="flex align-center cl-light" />
  </button>
</template>

<script>
import ChatIcon from "vue-material-design-icons/Forum.vue";
import PanUp from "vue-material-design-icons/PanUp.vue";
import Minus from "vue-material-design-icons/Minus.vue";
import Bell from "vue-material-design-icons/Bell.vue";
import BellOff from "vue-material-design-icons/BellOff.vue";

// import chatNotificationSound from '../assets/sounds/chat-notification-sound.mp3'

import { lobbyChatSendMesage } from "../firebase/db";

export default {
  data() {
    return {
      inputMessage: "",
      isLobbyChat: true,
      isSpaming: false,
      spamCount: 0,
    };
  },

  computed: {
    chatSound: {
      get() {
        return this.$store.state.chatSound;
      },
      set(value) {
        this.$store.commit("UPDATE_CHAT_SOUND", value);
      },
    },

    chatLength() {
      return this.chat.length;
    },
  },

  components: {
    ChatIcon,
    PanUp,
    Minus,
    Bell,
    BellOff,
  },

  props: {
    chat: Array,
    userProfile: Object,
    lobbyId: String,
    isModal:{
      type:Boolean,
      default:true
    }
  },

  mounted() {
    this.scrollToBottom();

    this.scrollToBottomNormal();
    this.windowSize();
  },

  methods: {
    openLobbyChat() {
      this.isLobbyChat = !this.isLobbyChat;
      this.scrollToBottomNormal();
    },

    windowSize() {
      if (window.innerWidth < 900) {
        this.isLobbyChat = this.isModal;
      } else {
        this.isLobbyChat = true;
      }
    },

    async sendMessage() {
      if (this.inputMessage.length > 0 && !this.isSpaming) {
        let userName = this.userProfile.userName;
        let message = {
          user: { userName: userName },
          message: this.inputMessage,
          createdAt: Date.now(),
        };
        await lobbyChatSendMesage(`${this.lobbyId}`, { ...message });
        this.inputMessage = "";
        this.scrollToBottomNormal();
      }
    },
    scrollToBottomNormal() {
      const container = this.$refs["messages-container"];

      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
    scrollToBottom() {
      let container = this.$refs["messages-container"];
      if (container.scrollTop > container.scrollHeight - 200) {
        this.$nextTick(() => {
          container.scrollTop = container.scrollHeight;
        });
      }
    },

    timestampToDate(time) {
      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      var date = new Date(time);
      // Hours part from the timestamp
      var hours = "0" + date.getHours();
      // Minutes part from the timestamp
      var minutes = "0" + date.getMinutes();
      // Seconds part from the timestamp

      // Will display time in 10:30:23 format
      var formattedTime = hours.substr(-2) + ":" + minutes.substr(-2);
      return formattedTime;
    },

    spamGuard() {
      let usersMessages = this.chat.filter((message) => {
        return message.user.userName === this.userProfile.userName;
      });
      let usersMessagesCreatedAt = usersMessages.filter((message) => {
        return message.createdAt > Date.now() - 5000;
      });

      if (usersMessagesCreatedAt.length > 2) {
        this.isSpaming = true;
        this.spamCount = 10;
        setTimeout(() => (this.isSpaming = false), 10000);
      }
    },

    chatNotification() {
      const sound = new Audio(
        require("@/assets/sounds/chat-notification-sound.mp3")
      );
      sound.volume = 0.1;
      if (this.chatSound) {
        sound.play();
      }
    },

    chatFo() {
      this.$emit("focusChat");
    },
  },

  emits: ["focusChat"],

  watch: {
    chat: function () {
      this.scrollToBottom();
      this.spamGuard();
    },

    chatLength(val, oldVal) {
      if (val > oldVal) {
        this.chatNotification();
      }
    },
    spamCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.spamCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>

<style scoped lang="scss">
.lobby-chat {
  display: flex;
  flex-direction: column;
  background: rgb(240, 240, 240);
  overflow: hidden;
  color: black;
  width: 100%;
  max-width: 100%;
  height: 90%;
  max-height: 100%;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
  margin: auto 0;
  min-height: 250px !important;
}

.lobby-chat:hover {
  opacity: 1;
}

.lobby-chat-title {
  display: flex;
  padding: 8px 32px 8px 48px;
  width: 100%;
  background: skyblue;
  font-weight: bold;
  font-size: 20px;
  color: white;
  align-items: center;
  justify-content: space-between;
}

.lobby-chat-messages {
  display: flex;
  flex-direction: column;
  padding: 6px 24px;
  overflow-y: auto;
  overflow-y: overlay;
  overflow-x: hidden;
}

.lobby-chat-message {
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
  margin: 2px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  word-break: break-word;
  width: fit-content;
  max-width: 90%;
  color: rgb(90, 90, 90);
}

.lobby-chat-message-user {
  font-weight: bold;
  padding: 2px 6px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgb(60, 60, 60);
}
.lobby-chat-message-time {
  margin-left: 12px;
  padding: 0 2px;
  font-size: 10px;
  font-weight: 500;
  color: rgb(100, 100, 100);
}
.lobby-chat-message-index {
  padding: 2px 8px;
  min-height: 24px;
}
.lobby-chat-message-sender {
  display: flex;
  width: 100%;
  padding: 4px;
  justify-content: center;
  margin-top: auto;
  border-radius: 12px;
  height: 40px;
  align-items: center;
  background: rgba(135, 207, 235, 0.2);
}

.lobby-chat-message-sender-input {
  display: flex;
  width: 75%;
  padding: 6px 8px;
  justify-content: center;
  margin-top: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 32px;
  border-radius: 12px;
  outline: none !important;
}
.lobby-chat-message-sender-button {
  display: flex;
  width: 10%;
  min-width: 32px;
  height: 32px;
  padding: 6px 0px;
  justify-content: center;
  margin-top: auto;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: skyblue;
  color: white;
  margin: 0 8px;
  cursor: pointer;
}

#chat-messages::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

#chat-messages::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#chat-messages::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: skyblue;
}

.lobby-chat-visibility-button {
  position: absolute;
  bottom: 12px;
  left: 12px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: pink;
  border-radius: 50%;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease-in;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

@media only screen and (max-width: 900px) {
  .lobby-chat-title {
    padding: 4px;
    padding-left: 12px;

    justify-content: space-around;
  }

  .lobby-chat-message-time {
    margin-left: 4px;
  }
}
</style>
