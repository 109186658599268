<template>
  <div class="app">
    <div class="kicked-from-lobby" v-if="kickedFromLobbyMessage">
      {{ kickedFromLobbyMessage }}
      <button class="btn" @click="kickedFromLobbyMessage = null">Ok</button>
    </div>

    <Loading v-if="pageLoading"></Loading>
    <Invite v-if="isInviteModal"></Invite>
    <router-view />
  </div>
</template>
<script>
import { onAuthStateChanged, auth } from "./firebase/db";
import Loading from "./components/Loading.vue";
import Invite from "./components/Invite.vue";

export default {
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("UPDATE_USER", value);
      },
    },

    pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    },

    isInviteModal: {
      get() {
        return this.$store.state.isInviteModal;
      },
      set(value) {
        this.$store.commit("UPDATE_IS_INVITE_MODAL", value);
      },
    },

    kickedFromLobbyMessage: {
      get() {
        return this.$store.state.kickedFromLobbyMessage;
      },
      set(value) {
        this.$store.commit("UPDATE_LOBBY_KICKED_MESSAGE", value);
      },
    },
  },

  mounted() {
    // this.getLang()
  },

  beforeMount() {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        user;
      } else {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // ...

        this.user = {
          userName: user.displayName,
          up: user.uid,
        };

        if (this.$route.path == "/login" || this.$route.path == "/register") {
          this.$router.push("/");
        }
      }
    });
  },

  components: {
    Loading,
    Invite,
  },

  watch: {
    $route() {},
  },

  methods: {
    getLang() {
      let language = window.navigator.userLanguage || window.navigator.language;
      localStorage.setItem("lang", language);
    },
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "Fredoka";
  src: local("Fredoka"),
    url(./assets/fonts/Fredoka/static/Fredoka/Fredoka-Regular.ttf)
      format("truetype");
}

.app {
  font-family: Fredoka, Helvetica, sans-serif;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: purple;
  background-image: url("~@/assets/images/app-background.png");

  background-size: cover;
}

// .app-background {
//   display: flex;
//   width: 100vw;
//   height: 200vh;
//   position: absolute;
//   top: -50vh;
//   left: -50vw;
//   right: 0;
//   bottom: 0;
//   user-select: none;
//   pointer-events: none;
//   object-fit: cover;
// }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.kicked-from-lobby {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  font-size: 36px;
  font-weight: bold;
  color: white;
  z-index: 99;
}


.app::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #f5f5f500;
}

.app::-webkit-scrollbar
{
	width: 12px;
	background-color: #f5f5f500;

}

.app::-webkit-scrollbar-thumb
{
	border-radius: 10px;
box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

*::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #e42e2e00;

}

*::-webkit-scrollbar
{
	width: 8px;
	background-color: purple;
}

*::-webkit-scrollbar-thumb
{
  
	border-radius: 10px;
box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(97, 0, 60, 0.2);
}
</style>
