<template>
  <div class="languages" @click.self="closeModal">
<div class="languages-wrapper">
    <div class="times" @click="closeModal">x</div>

    <div class="languages-header ">
<div class="languages-header-title">
    {{$t('languages.select-language')}}
</div>

    </div>
    <div :class="selectedLanguage == language.abbr ? 'language-selected' : ''" class="language" v-for="language in languages.sort((a, b) => a.name.localeCompare(b.name))" :key="language.abbr" @click="changeLanguage(language.abbr)">
        {{language.name}}
    </div>
</div>
  </div>
</template>

<script>
export default {
data(){
    return{
        languages:[
             {
                abbr:'en',
                name:'English',
                isSelected:false
            },
            {
                abbr:'tr',
                name:'Turkish',
                isSelected:false

            },
           
        ]
    }
},

methods:{
    closeModal(){
        this.$emit('closeLanguagesModal')
    },

changeLanguage(abbr){
    this.selectedLanguage = abbr
    this.$router.go(0)
}
},
computed:{
    selectedLanguage:{
    get() {
        return localStorage.getItem('lang')
      },
      set(value) {
       localStorage.setItem('lang', value)
      },
    }
}
}


</script>

<style scoped lang="scss">
.languages{
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    display:flex;
    justify-content: center;
    align-items: center;
}

.languages-wrapper{
    display:flex;
    flex-direction: column;
    height: 460px;
    width: 360px;
    max-width: 100%;
    max-height: 100%;
    background: whitesmoke;
    padding: 8px;
    border-radius: 12px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
    align-items: center;
    overflow-x:hidden;
    overflow-y: auto;
    overflow-y: overlay;

    .times{
        margin-left: auto;
        margin-right:8px;
        font-size: 25px;
        width: 30px;
        height: 30px;
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: gray;
        cursor: pointer;
    }
    .times:hover{
        opacity: 0.5;
    }
}

.language{
    display:flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 40px;
    background: rgba(128, 128, 128, 0.2);
    margin: 4px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    color: rgb(82, 82, 82);
    transition: 0.3s all ease;
}

.languages-header{
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
   *{
        font-size: 20px;
    font-weight: bold;
    color: orange;
   }

}
.language:hover{
     background: rgba(48, 48, 48, 0.5);
    color: white;
}

.language-selected{
    background: orange;
    color: white;

}
</style>