import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";

import Lobbies from "../views/Lobbies.vue";
import Game from "../views/Game.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import GuestRegister from "../views/GuestRegister.vue";
import CreateLobby from "../views/CreateLobby.vue";
import NotFound from "../views/NotFound.vue";
// import HowToPlay from "../views/HowToPlay.vue";
import Privacy from "../views/Policies/Privacy.vue";
import Cookies from "../views/Policies/Cookies.vue";

import Kuizbook from "../views/Kuizbook/Kuizbook.vue";
import KuizInfo from "../views/Kuizbook/KuizInfo.vue";


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/create-lobby",
    name: "Create Lobby",
    component: CreateLobby,
  },

  {
    path: "/lobbies",
    name: "Lobbies",

    component: Lobbies,
  },
  {
    path: "/guest-register",
    name: "Guest Register",
    component: GuestRegister,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  // {
  //   path: "/how-to-play",
  //   name: "how-to-play",
  //   component: HowToPlay,
  // },
  {
    path: "/game/:id",
    name: "Game",
    component: Game,
  },
  {
    path: "/kuizbook",
    name: "Kuizbook",
    component: Kuizbook,
  },
  {
    path: "/kuiz/:id",
    name: "kuiz",
    component: KuizInfo,
  },
  { path: "/privacy", component: Privacy },
  { path: "/cookies", component: Cookies },

  
  { path: "/:pathMatch(.*)", component: NotFound },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
