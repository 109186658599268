<template>
  <div class="settings-wrapper slideFromLeftAnimation">
    <CategorySettings
      :userProfile="userProfile"
      :settings="settings"
    ></CategorySettings>
    <div class="setting-wrapper">
      <div class="setting-wrapper-title">{{ $t("game.turn-time") }} (5s-120s)</div>
      <input
        v-if="this.userProfile.isAdmin"
        class="setting-input"
        :max="maxLocaleTurnTime"
        :min="minLocaleTurnTime"
        v-model.number="localeTurnTime"
        type="number"
        @blur="enforceMinMax()"
        @change="enforceMinMax()"
        @input="enforceMinMax()"


        :style="[
          localeTurnTime < minLocaleTurnTime ? 'color:red' : '',
          localeTurnTime == minLocaleTurnTime ? 'color:orange;' : '',
          localeTurnTime > maxLocaleTurnTime ? 'color:red' : '',
          localeTurnTime == maxLocaleTurnTime ? 'color:orange;' : '',
        ]"
      />
      <div class="setting-input" v-else>
        {{ localeTurnTime }}
      </div>
    </div>

        <div class="setting-wrapper">
      <div class="setting-wrapper-title">{{ $t("game.question-size") }} (1-30)</div>
      <input
        v-if="this.userProfile.isAdmin"
        class="setting-input"
        :max="maxQuestionSize"
        :min="minQuestionSize"
        v-model.number="localeQuestionSize"
        type="number"
        @blur="enforceMinMax()"
        @change="enforceMinMax()"
        @input="enforceMinMax()"


        :style="[
          localeQuestionSize < minQuestionSize ? 'color:red' : '',
          localeQuestionSize == minQuestionSize ? 'color:orange;' : '',
          localeQuestionSize > maxQuestionSize ? 'color:red' : '',
          localeQuestionSize == maxQuestionSize ? 'color:orange;' : '',
        ]"
      />
      <div class="setting-input" v-else>
        {{ localeQuestionSize }}
      </div>
    </div>
  </div>
</template>

<script>
import { updateLobbySettings } from "../../firebase/db";
import CategorySettings from "./CategorySettings.vue";

export default {
  data() {
    return {
      minLocaleTurnTime: 5,
      maxLocaleTurnTime: 120,
      minQuestionSize:1,
      maxQuestionSize:30
    };
  },
  methods: {
    async turnTimeChanged(val) {
      if (!this.userProfile.isAdmin) return;

      const setts = this.settings;
      val < 5 ? (val = 5) : val > 120 ? (val = 120) : (setts.turnTime = val);
      await updateLobbySettings(`${this.$route.params.id}`, setts);
    },
        async questionSizeChanged(val) {
      if (!this.userProfile.isAdmin) return;

      const setts = this.settings;
      val < 1 ? (val = 1) : val > 30 ? (val = 30) : (setts.questionSize = val);
      await updateLobbySettings(`${this.$route.params.id}`, setts);
    },

    enforceMinMax(){
      this.localeTurnTime < 5 ? (this.localeTurnTime = 5) : ''
       this.localeTurnTime > 120 ? (this.localeTurnTime = 120) : ''

},
  enforceMinMaxQ(){
      this.localeQuestionSize < this.minQuestionSize ? (this.localeQuestionSize = this.minQuestionSize) : ''
       this.localeQuestionSize > this.maxQuestionSize ? (this.localeQuestionSize = this.maxQuestionSize) : ''

},

  },

  computed: {
    localeTurnTime: {
      get() {
        return this.settings === null ? 15 : this.settings.turnTime;
      },
      async set(value) {
        await this.turnTimeChanged(value);

        return value;
      },
    },
        localeQuestionSize: {
      get() {
        return this.settings === null ? 20 : this.settings.questionSize;
      },
      async set(value) {
        await this.questionSizeChanged(value);

        return value;
      },
    },
  },
  props: {
    settings: Object,
    userProfile: Object,
  },
  components: { CategorySettings },

};
</script>

<style scoped lang="scss">
.settings-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 55vw;
  height: calc(55vw * (9 / 16)); /* 100/56.25 = 1.778 */
  background: rgb(129, 28, 143);
  max-height: calc(1025px * (9 / 16));
  max-width: 1025px; /* 16/9 = 1.778 */
  top: 0;
  bottom: 0; /* vertical center */
  left: 0;
  right: 0; /* horizontal center */
  margin: auto;
  color: black;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 3px 3px 16px 4px rgba(0, 0, 0, 0.2),
    0 0 1px 2px rgba(0, 0, 0, 0.1) inset, 0 0 1px 4px rgba(0, 0, 0, 0.05) inset,
    0 0 1px 6px rgba(0, 0, 0, 0.03) inset;
}

.setting-wrapper {
  display: flex;
  min-width: 16rem;

  flex-direction: column;
  position: relative;
  height: fit-content;
}
.setting-wrapper-title {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: whitesmoke;
   text-shadow: 0.15rem 0.15rem 0.1rem rgb(211, 144, 255);

  padding: 2px;
}
.setting-input {
  display: flex;
  height: 3.3rem;
  padding: 12px;
  justify-content: center;
  align-items: center;
  background: rgb(218, 79, 236);

  border-radius: 1rem;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  color: whitesmoke;
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow: 1px 1px 1px black;
  box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.2),
    0 0 1px 2px rgba(0, 0, 0, 0.1) inset, 0 0 1px 4px rgba(0, 0, 0, 0.05) inset,
    0 0 1px 6px rgba(0, 0, 0, 0.03) inset;
  outline: none;
  border: none;
  width: 70%;
  margin: auto;
  text-align: center;
}

@media only screen and (max-width: 1100px) {
  .settings-wrapper {
    width: 98vw;
    height: 56.25vw; /* 100/56.25 = 1.778 */
    max-height: 70vh;
    max-width: calc(70vh * (16 / 9)); /* 16/9 = 1.778 */
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15rem;
  }
}
</style>
