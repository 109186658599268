<template>
  <div>
    <a
      v-if="lobby && !this.isStarted"
      class="create-lobby-back-button"
      @click="$router.go(-1)"
    >
      <ArrowLeft :size="32"></ArrowLeft>
    </a>
    <Leaderboard :userid="userProfile.id" v-if="leaderboard"></Leaderboard>
    <div v-if="lobbyNotFound && user" class="create-lobby">
      <router-link class="create-lobby-back-button" to="/">
        <ArrowLeft :size="32"></ArrowLeft>
      </router-link>
      <div class="cl-light m-4">{{ $t("game.lobby-not-found") }}</div>
      <button class="btn" @click="$router.push('/create-lobby')">
        {{ $t("game.create-lobby") }}
      </button>
    </div>

    <div v-if="lobbyNotFound && !user" class="create-lobby">
      <div class="cl-light m-4">
        {{ $t("game.login-first") }}
      </div>
      <button class="btn" @click="$router.push('/')">
        {{ $t("game.home") }}
      </button>
    </div>

    <div v-if="!lobbyNotFound && user">
      <div class="create-lobby" v-if="isPassword">
        <a class="create-lobby-back-button" @click="$router.go(-1)">
          <ArrowLeft :size="32"></ArrowLeft>
        </a>
        <div
          @keyup.enter="checkPassword"
          class="create-lobby-form slideFromLeftAnimation shadow-1"
        >
          <div class="form-element slideFromLeftAnimation">
            <span>
              {{ $t("game.password") }}
            </span>
            <input
              v-model="tryPassword"
              @input="passwordError = ''"
              id="lobbyPassword"
              name="lobbyPassword"
              type="password"
            />
          </div>

          <div class="form-element-error slideFromLeftAnimation">
            <span>{{ passwordError }}</span>
          </div>
          <div class="form-element-button slideFromLeftAnimation">
            <button class="btn" @click="checkPassword">Ok</button>
          </div>
        </div>
      </div>
      <div v-if="!isPassword">
        <div id="game" class="game" v-if="!isPassword && userProfile">
          <div v-if="!this.isStarted" class="game-container">
            <div class="words-container">
              <SettingsWrapper
                :userProfile="userProfile"
                :settings="lobby.settings"
              ></SettingsWrapper>
            </div>
            <div class="game-container-sidebar">
              <UsersTab
                :users="lobby.users"
                :userProfile="userProfile"
                :userLimit="lobby.userLimit"
                :lobbyName="lobby.name"
                :lobbyId="lobby._id"
                :questionSize="lobby.settings.questionSize"
              ></UsersTab>

              <LobbyChat
                :chat="lobby.chat"
                :userProfile="userProfile"
                :lobbyId="lobby._id"
              ></LobbyChat>

              <div
                class="invite-button-wrapper flex justify-space-around align-center"
              >
                <button class="btn" @click="isInviteModal = true">
                  {{ $t("game.invite") }}
                </button>
                <button class="btn">
                  {{ $t("game.feedback") }}
                </button>
              </div>

              <div class="invite-button-wrapper-sm flex justify-space-around">
                <button class="btn" @click="isInviteModal = true">
                  {{ $t("game.invite") }}
                </button>
              </div>
              <div class="invite-button-wrapper-sm flex justify-space-around">
                <button class="btn">
                  {{ $t("game.feedback") }}
                </button>
              </div>
            </div>
          </div>

          <ActiveGame
            :userProfile="userProfile"
            :chat="lobby.chat"
            :lobbyId="lobby._id"
            @stopShow="stopTheShow"
            v-if="showMustGoOn"
          ></ActiveGame>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LobbyChat from "../components/LobbyChat.vue";
import SettingsWrapper from "../components/LobbySettings.vue/SettingsWrapper.vue";
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";
import ActiveGame from "../components/ActiveGame/ActiveGame.vue";
import Leaderboard from "../components/ActiveGame/Leaderboard.vue";

import {
  addLobbyUser,
  db,
  doc,
  onSnapshot,
  assignAdmin,
  leaveLobby,
  getLobby,
  auth,
  deleteCollection,
} from "../firebase/db";
import UsersTab from "../components/UsersTab.vue";
export default {
  data() {
    return {
      lobby: null,
      lobbyNotFound: false,
      tryPassword: "",
      isPassword: true,
      passwordPassed: false,
      passwordError: "",
      tryUserName: "",
      userNameError: "",
      userip: "",
      isKicked: false,
      showMustGoOn: false,
    };
  },

  title() {
    return `${this.lobby ? this.lobby.name : ""} Lobby | Game | okuiz `;
  },

  components: {
    LobbyChat,
    UsersTab,
    ArrowLeft,
    Leaderboard,
    SettingsWrapper,
    ActiveGame,
  },

  mounted() {
    this.getLobbyRealTime();
    this.createUser();
    if (process.env.NODE_ENV === "production") {
      window.addEventListener("beforeunload", () => {
        this.leaveRoom();
      });
    }
  },

  created() {},

  computed: {
    userProfile() {
      if (this.lobby && auth.currentUser) {
        return this.lobby.users.find((user) => {
          let isUserIp =
            user.id == auth.currentUser.uid &&
            auth.currentUser.displayName == user.userName;

          return isUserIp;
        });
      } else {
        return false;
      }
    },
    leaderboard: {
      get() {
        return this.$store.state.leaderboard;
      },
      set(value) {
        this.$store.commit("UPDATE_LEADERBOARD", value);
      },
    },

    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("UPDATE_USER", value);
      },
    },

    invitePath: {
      get() {
        return this.$store.state.invitePath;
      },
      set(value) {
        this.$store.commit("UPDATE_INVITE_PATH", value);
      },
    },

    isInviteModal: {
      get() {
        return this.$store.state.isInviteModal;
      },
      set(value) {
        this.$store.commit("UPDATE_IS_INVITE_MODAL", value);
      },
    },

    isStarted: function () {
      return this.lobby ? this.lobby.isStarted : null;
    },
  },

  methods: {
    getLobbyRealTime() {
      onSnapshot(doc(db, "lobbies", `${this.$route.params.id}`), (docs) => {
        this.lobby = docs.data();
      });
    },

    isLobby() {
      if (!this.lobby) {
        this.lobbyNotFound = true;
      } else {
        this.lobbyNotFound = false;
      }
    },

    isPasswordCheck() {
      if (!this.passwordPassed && this.lobby) {
        if (this.lobby.password.length > 0) {
          this.isPassword = true;
        } else {
          this.isPassword = false;
        }
      }
    },

    checkPassword() {
      if (this.tryPassword == this.lobby.password) {
        this.isPassword = false;
        this.passwordPassed = true;
        this.createUser();
      } else {
        this.passwordError = this.$t("game.false-password");
      }
    },

    async createUser() {
      if (this.isKicked) {
        return;
      }

      const thisLobby = await getLobby(`${this.$route.params.id}`);
      let isSameUser = false;
      if (auth.currentUser) {
        isSameUser = thisLobby.users.find((user) => {
          let isUserIp =
            user.id == auth.currentUser.uid &&
            user.userName == auth.currentUser.displayName;

          return isUserIp;
        });
      }

      if (isSameUser) {
        return;
      }

      if (thisLobby.userLimit <= thisLobby.users.length) {
        this.$store.state.kickedFromLobbyMessage = this.$t("game.lobby-full");

        this.$router.push("/lobbies");
      } else {
        if (this.user) {
          if (!this.isPassword) {
            let user = new Object();
            user.userName = auth.currentUser.displayName;

            user.points = 0;
            user.joinedAt = Date.now();
            user.isAdmin = false;
            user.isReady = false;
            user.id = this.user.up;
            user.isAnonymous = auth.currentUser.isAnonymous;
            addLobbyUser(`${this.$route.params.id}`, { ...user });
          }
        } else {
          this.invitePath = this.$route.path;

          this.$router.push("/");
          return;
        }
      }
    },

    isLobbyAdmin() {
      if (this.lobby) {
        if (this.lobby.users.length > 0) {
          let isAdmin = this.lobby.users.find((user) => {
            return user.isAdmin == true;
          });

          if (!isAdmin) {
            let user = this.lobby.users[0];

            assignAdmin(`${this.$route.params.id}`, user);
          }
        }
      }
    },

    async leaveRoom() {
      let leavingUser = this.userProfile;
      if (leavingUser) {
        if (this.lobby.users.length < 2) {
          if (this.isStarted) {
            await deleteCollection("activeGames", `${this.lobby._id}`).then(
              await deleteCollection("lobbies", `${this.lobby._id}`)
            );
          } else {
            await deleteCollection("lobbies", `${this.lobby._id}`);
          }
        } else {
          leaveLobby(this.lobby._id, leavingUser);
        }
      }else{
        if(this.lobby.users.length == 0){
            if (this.isStarted) {
            await deleteCollection("activeGames", `${this.lobby._id}`).then(
              await deleteCollection("lobbies", `${this.lobby._id}`)
            );
          } else {
            await deleteCollection("lobbies", `${this.lobby._id}`);
          }
        }
      }
    },

    amikicked() {
      if (this.lobby) {
        let ami = this.lobby.kickedUsers.find((id) => {
          return id == this.user.up;
        });
        this.isKicked = ami;
        if (ami) {
          this.$router.push("/");
          this.$store.state.kickedFromLobbyMessage = this.$t(
            "game.kicked-from-lobby"
          );
        }
      }
    },

    stopTheShow() {
      this.showMustGoOn = false;
    },
  },

  watch: {
    lobby: {
      handler() {
        this.isLobby();
        this.isPasswordCheck();
        this.isLobbyAdmin();
        this.amikicked();
        if (this.isStarted) {
          this.showMustGoOn = true;
        }
      },
    },
  },

  beforeUnmount() {
    this.leaveRoom();

    this.getLobbyRealTime();
    window.removeEventListener("beforeunload", () => {
      this.leaveRoom();
    });
  },

  emits: ["stopShow"],
};
</script>

<style scoped lang="scss">
.game {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;

  position: relative;
  color: whitesmoke;
}

.game-container {
  display: grid;
  min-height: 100vh;
  height: 100%;
  width: 1500px;
  max-width: 100%;
  grid-template-columns: 75% 25%;
}

.game-container-sidebar {
  display: grid;
  max-width: 300px;
  height: 100vh;
  max-height: 1400px;
  align-self: center;
  grid-template-rows: 5fr 3fr 1fr;
  overflow-y: auto;
  overflow-x: hidden;
}

.words-container {
  display: grid;
  justify-self: center;
  max-width: 1200px;
  grid-template-rows: 70% 20% 10%;
}
.invite-button-wrapper-sm {
  display: none;
}
@media only screen and (max-width: 1100px) {
  .lobby-info {
    display: none;
  }

  .game-container {
    padding-top: 4rem;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .words-container {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .game-container-sidebar {
    display: grid;
    height: 50vh;
    gap: 2%;
    padding: 2%;
    width: 98%;
    max-width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: 80% 20%;
    min-height: 400px !important;
    overflow: hidden;
  }

  .invite-button-wrapper {
    display: none;
  }

  .invite-button-wrapper-sm {
    display: flex;
  }
}
</style>
