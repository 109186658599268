<template>
  <div class="create-lobby">
    <router-link class="create-lobby-back-button" to="/">
      <ArrowLeft :size="32"></ArrowLeft>
    </router-link>
    <div class="lobbies-title">
      {{ $t("login.login") }}
    </div>

    <div
      @keyup.enter="login"
      class="create-lobby-form slideFromLeftAnimation shadow-1"
    >
      <div class="form-element slideFromLeftAnimation">
        <span>
          {{ $t("login.email") }}
        </span>
        <input @input="loginError = ''" v-model="tryEmail" />
      </div>
      <div class="form-element slideFromLeftAnimation">
        <span>
          {{ $t("login.password") }}
        </span>
        <input @input="loginError = ''" type="password" v-model="tryPassword" />
      </div>

      <div class="form-element-error slideFromLeftAnimation">
        <span>{{ loginError }}</span>
      </div>
      <div class="form-element-button slideFromLeftAnimation">
        <button class="btn" @click="login">
          {{ $t("login.login") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";
import { auth, signInWithEmailAndPassword } from "../firebase/db";

export default {
  data() {
    return {
      tryEmail: "",
      tryPassword: "",
      loginError: "",
    };
  },

  mounted() {},

  title() {
    return "Login | okuiz";
  },

  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("UPDATE_USER", value);
      },
    },

    invitePath: {
      get() {
        return this.$store.state.invitePath;
      },
      set(value) {
        this.$store.commit("UPDATE_INVITE_PATH", value);
      },
    },

    pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    },
  },

  methods: {
    login() {
      this.pageLoading = true;
      signInWithEmailAndPassword(auth, this.tryEmail, this.tryPassword)
        .then(() => {
          // Signed in

          // ...
          this.invitePath
            ? this.$router.push(`${this.invitePath}`)
            : this.$router.push("/");
          this.pageLoading = false;

          this.invitePath = null;
        })
        .catch((error) => {
          const errorCode = error.code;

          if (errorCode) {
            this.loginError = `${this.$t('login.errors.invalid')}`;
          }

          this.pageLoading = false;
        });
    },
  },

  components: {
    ArrowLeft,
  },
};
</script>

<style></style>
