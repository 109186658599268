<template>
  <div class="navbar-wrapper">
    <div class="navbar">
      <router-link to="/" class="navbar-logo"
        >okuiz
        <div class="navbar-logo-beta">BETA</div>
      </router-link>
      <div class="navbar-social-medias">
        <a
          href="https://facebook.com/okuiz"
          target="blank"
          class="navbar-social-media"
        >
          <Facebook></Facebook>
        </a>
        <a
          href="https://twitter.com/okuizgame"
          target="blank"
          class="navbar-social-media"
        >
          <Twitter></Twitter>
        </a>
        <a
          href="https://www.instagram.com/okuizgame/"
          target="blank"
          class="navbar-social-media"
        >
          <Instagram></Instagram>
        </a>
      </div>
      <div class="navbar-right">
      <NavbarRoutes></NavbarRoutes>

        <button
          @click="isLanguagesModal = !isLanguagesModal"
          class="navbar-languages"
        >
          <Web></Web>
        </button>

        <UserProfile></UserProfile>
      </div>
    </div>
    <LanguagesModal
      @closeLanguagesModal="isLanguagesModal = false"
      v-if="isLanguagesModal"
    ></LanguagesModal>
  </div>
</template>

<script>
import UserProfile from "./UserProfile.vue";
import Facebook from "vue-material-design-icons/Facebook.vue";
import Twitter from "vue-material-design-icons/Twitter.vue";
import Instagram from "vue-material-design-icons/Instagram.vue";
import Web from "vue-material-design-icons/Web.vue";

import LanguagesModal from "../LanguagesModal.vue";
import NavbarRoutes from "./NavbarRoutes.vue";

export default {
  components: {
    UserProfile,
    Facebook,
    Twitter,
    Instagram,
    Web,
    LanguagesModal,
    NavbarRoutes,
  },

  data() {
    return {
      isLanguagesModal: false,
    };
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  margin: 1rem 0;
  width: 90vw;
  max-width: 100%;
  align-items: center;
  padding: 0 5%;
  height: 70px;
  background-color: rgba(128, 0, 128, 0.9);
  border-bottom: 2px solid rgb(255, 204, 62);
  box-shadow: 0px -5px 5px 1px rgba(255, 166, 0, 0.1) inset,
    0px -13px 10px 2px rgba(255, 166, 0, 0.2) inset,
    0 0 5px 1px rgba(255, 166, 0, 0.105), 0 0 10px 2px rgba(255, 166, 0, 0.514),
    0 5px 10px 2px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  position: relative;
  z-index: 15 !important;
}

.navbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
  z-index: 15 !important;
}

.navbar-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  *{
    margin: 0 4px;
  }
  z-index: 5;
}
.navbar-social-medias {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  z-index: 6;
}

.navbar-social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(105, 43, 133, 0.466);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1) inset,
    0 0 16px 1px rgba(0, 0, 0, 0.1) inset, 0 0 8px 1px rgba(255, 255, 255, 0.1);
  margin: 0 2px;
  color: rgb(255, 255, 255);
  * {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
  }
  transition: 0.5s all ease;
}
.navbar-social-media:hover {
  opacity: 0.5;
}

.navbar-languages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(105, 43, 133, 0.466);
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1) inset,
    0 0 16px 1px rgba(0, 0, 0, 0.1) inset, 0 0 8px 1px rgba(255, 255, 255, 0.1);
  margin: 0 8px;
  color: rgb(255, 255, 255);
  * {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }
  transition: 0.5s all ease;
  z-index: 5;
}

.navbar-languages:hover {
  opacity: 0.5;
}
.navbar-logo {
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-shadow: 0.15rem 0.15rem orange;
  animation: glow 0.5s cubic-bezier(0.86, 0, 0.07, 1) infinite;
  position: relative;
  z-index: 6;
}
.navbar-logo-beta {
  position: absolute;
  top: -6%;
  right: -20%;
  color: rgb(253, 204, 67);
  border: 0.05rem solid orange;
  padding: 0.05rem;
  border-radius: 0.2rem;
  text-shadow: none;
  font-size: 12px;
}

@media only screen and (max-width: 1080px) {
  .navbar-social-medias {
    display: none;
  }

  .navbar-languages {
    margin: 0;
  }
}
@media only screen and (max-width: 400px) {
  .navbar-logo {
    font-size: 25px;
  }
  .navbar-logo-beta {
    position: absolute;
    top: -6%;
    right: -20%;

    font-size: 7px;
  }
}
</style>
