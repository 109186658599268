<template>
  <div>
      <Navbar></Navbar>
      <div class="how-to-play-wrapper">
              <div class="flex align-center justify-center" >
        <div class="line"></div>

        <h1 class="how-to-play-title" >
         {{ $t(`home.contact`) }}
        </h1>
        <div class="line"></div>
      </div>

      <h5 class="how-to-play-subtitle" >
        <div class="subtitle-ok">
            Email: <a href="mailto:support@okuiz.com">support@okuiz.com</a> 
        </div>
      </h5>
      </div>
      <MainFooter></MainFooter>
  </div>
</template>

<script>
import Navbar from "../components/Navbar/Navbar.vue";
import MainFooter from "../components/MainFooter.vue";
export default {
    components: { Navbar, MainFooter }
}
</script>


<style lang="scss" scoped>


.how-to-play-wrapper {
  width: 1025px;
  max-width: 100%;
  margin: 120px auto 0 auto;
  padding-top: 12px;
  position: relative;
  min-height: 100vh;
}

.how-to-play-title {
  font-size: 4rem;
  color: white;
  text-shadow: 0.15rem 0.15rem orange;
  font-weight: bold;
  display: flex;
  width: fit-content;
  word-break: keep-all;
  white-space: nowrap;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.line {
  display: flex;
  width: 100%;
  border-bottom: 3px solid orange;
  border-radius: 20px;
}
.how-to-play-subtitle {
  font-size: 1.5rem;
  color: orange;
  font-style: italic;
  margin: 8px;
}

.subtitle-ok {
  font-size: 1.7rem;
  color: white;
  text-shadow: 0.1rem 0.1rem orange;
  letter-spacing: 2px;

  font-style: italic;
  *{
        font-size: 1.7rem;
  color: white;
  text-shadow: 0.1rem 0.1rem orange;
  letter-spacing: 2px;

  font-style: italic;
  }
}



</style>