<template>
  <div class="create-lobby">
    <router-link class="create-lobby-back-button" to="/">
      <ArrowLeft :size="32"></ArrowLeft>
    </router-link>
    <div class="lobbies-title">
      {{ $t("register.register") }}
    </div>

    <div
      @keyup.enter="register"
      class="create-lobby-form slideFromLeftAnimation shadow-1"
    >
      <div class="form-element slideFromLeftAnimation">
        <span>
          {{ $t("register.email") }}
        </span>
        <input @input="registerError = ''" v-model="tryEmail" />
      </div>
      <div class="form-element slideFromLeftAnimation">
        <span>
          {{ $t("register.username") }}
        </span>
        <input
          maxlength="12"
          @input="registerError = ''"
          v-model="tryUserName"
        />
      </div>
      <div class="form-element slideFromLeftAnimation">
        <span>
          {{ $t("register.password") }}
        </span>
        <input
          @input="registerError = ''"
          type="password"
          v-model="tryPassword"
        />
      </div>
      <div class="form-element slideFromLeftAnimation">
        <span>
          {{ $t("register.password-again") }}
        </span>
        <input
          @input="registerError = ''"
          type="password"
          v-model="tryPassword2"
        />
      </div>
      <div class="form-element-error slideFromLeftAnimation">
        <span>{{ registerError }}</span>
      </div>
      <div class="form-element-button slideFromLeftAnimation">
        <button class="btn" @click="register">
          {{ $t("register.register") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";
import {
  auth,
  createUserWithEmailAndPassword,
  createUser,
  updateProfile,
} from "../firebase/db";

export default {
  data() {
    return {
      tryEmail: "",
      tryUserName: "",
      tryPassword: "",
      tryPassword2: "",
      registerError: "",
    };
  },
  title() {
    return "Register | okuiz";
  },
  mounted() {},

  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("UPDATE_USER", value);
      },
    },

    invitePath: {
      get() {
        return this.$store.state.invitePath;
      },
      set(value) {
        this.$store.commit("UPDATE_INVITE_PATH", value);
      },
    },

    pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },
    },
  },

  methods: {
    async register() {
      this.pageLoading = true;
      if (this.tryPassword != this.tryPassword2) {
        this.registerError = `${this.$t('register.errors.passwords-not-match')}`;
            this.pageLoading = false;

      } else {
        await createUserWithEmailAndPassword(
          auth,
          this.tryEmail,
          this.tryPassword
        )
          .then((userCredential) => {
            // Signed in

            updateProfile(auth.currentUser, {
              displayName: this.tryUserName,
            })
              .then(() => {
                this.pageLoading = false;

                this.user = {
                  userName: auth.currentUser.displayName,
                  up: auth.currentUser.uid,
                };

                this.invitePath
                  ? this.$router.push(`${this.invitePath}`)
                  : this.$router.push("/");

                this.invitePath = null;
              })
              .catch((error) => {
                return error;
              });

            const user = userCredential.user;
            const userForDb = {
              userName: this.tryUserName,
              email: user.email,
              createdAt: Date.now(),
            };

            createUser(user.uid, userForDb);

            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            this.pageLoading = false;

            // ..
            if (errorCode == "auth/email-already-in-use") {
              this.registerError = `${this.$t('register.errors.email-already')}`;
            } else if (errorCode == "auth/invalid-email") {
              this.registerError = `${this.$t('register.errors.invalid-email')}`;
            } else if (errorCode == "auth/weak-password") {
              this.registerError =
                `${this.$t('register.errors.password-length')}`;
            }
          });
      }
    },
  },
  components: {
    ArrowLeft,
  },
};
</script>

<style></style>
