<template>
  <!-- <div class="create-lobby">
    <router-link class="create-lobby-back-button" to="/">
      <ArrowLeft :size="32"></ArrowLeft>
    </router-link>
    <div
      @keyup.enter="createUser"
      class="create-lobby-form slideFromLeftAnimation shadow-1"
    >
      <div class="form-element slideFromLeftAnimation">
        <span>Username</span>
        <input
          maxlength="12"
          @input="userNameError = ''"
          v-model="tryUserName"
        />
      </div>
      <div class="form-element-error slideFromLeftAnimation">
        <span>{{ userNameError }}</span>
      </div>
      <div class="form-element-button slideFromLeftAnimation">
        <button class="btn" @click="guestRegister">Ok</button>
      </div>
    </div>
  </div> -->
  <div></div>
</template>

<script>
// import ArrowLeft from "vue-material-design-icons/ArrowLeft.vue";
import { auth, signInAnonymously, updateProfile } from "../firebase/db";

export default {
  data() {
    return {
      userNameError: "",
      up: "",
    };
  },

  async mounted() {
    // await this.getIp();
    this.guestRegister();
  },
 title() {
    return "Guest Login | okuiz";
  },
  computed: {
    invitePath: {
      get() {
        return this.$store.state.invitePath;
      },
      set(value) {
        this.$store.commit("UPDATE_INVITE_PATH", value);
      },
    },

    user: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("UPDATE_USER", value);
      },
    },

      pageLoading: {
      get() {
        return this.$store.state.pageLoading;
      },
      set(value) {
        this.$store.commit("UPDATE_PAGE_LOADING", value);
      },}
  },

  methods: {
    // async getIp() {
    //   await fetch("https://api.ipify.org?format=json")
    //     .then((x) => x.json())
    //     .then(({ ip }) => {
    //       this.up = ip;
    //     });
    // },

    createId() {
      const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let idLength = 8;
      let myId = "";
      let whichArray = ["number", "upper", "lower"];

      for (let i = 0; i < idLength; i++) {
        let selected = whichArray[Math.floor(Math.random() * 3)];
        if (selected == "upper") {
          myId = myId + alphabet[Math.floor(Math.random() * alphabet.length)];
        } else if (selected == "lower") {
          myId =
            myId +
            alphabet[Math.floor(Math.random() * alphabet.length)].toLowerCase();
        } else if (selected == "number") {
          myId = myId + Math.floor(Math.random() * 10);
        }
      }

      return myId;
    },

    async guestRegister() {
      this.pageLoading = true
      await signInAnonymously(auth)
        .then(() => {
          // Signed in..
          updateProfile(auth.currentUser, {
            displayName: "user" + this.createId(),
          })
            .then(() => {
              // Profile updated!
              // ...
                this.user = {
      userName: auth.currentUser.displayName,
up:auth.currentUser.uid
    }

          this.invitePath
        ? this.$router.push(`${this.invitePath}`)
        : this.$router.push("/lobbies");
      this.invitePath = null;
      this.pageLoading = false

            })
            .catch((error) => {
              return error;
            });


        })
        .catch((error) => {
      this.pageLoading = false

      return error
          // ...
        });



    },
  },

  components: {
    // ArrowLeft
  },
};
</script>

<style></style>
